/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import { FETCH_DATA, POST_DATA } from "../middlewares/api";
import { combineReducers } from "redux";
import { schema as homeProductSchema } from "./entities/homeProducts";
import { schema as productConditionSchema } from "./entities/productConditions";
import url from "../../utils/url";
import { actionTypesConstructor, dispatchAction } from "../utils/index";
import { isFetchingMyBids } from "./dashboard";
import { exploreProductsSchema } from "./entities/exploreProducts";

export const params = {
  PATH_HOME_PRODUCTS: "homeProducts",
  PAGE_SIZE_HOME_PRODUCTS: 28,
};

export const types = {
  FETCH_HOME_PRODUCTS: actionTypesConstructor(
    "HOME/FETCH_HOME_PRODUCTS_REQUEST",
    "HOME/FETCH_HOME_PRODUCTS_SUCCESS",
    "HOME/FETCH_HOME_PRODUCTS_FAILURE"
  ),
  FETCH_HOME_PRODUCT_CONDITIONS: actionTypesConstructor(
    "HOME/FETCH_PRODUCT_CONDITION_REQUEST",
    "HOME/FETCH_PRODUCT_CONDITION_SUCCESS",
    "HOME/FETCH_PRODUCT_CONDITION_REQUEST"
  ),
  FETCH_EXPLORE_PRODUCTS: actionTypesConstructor(
    "HOME/EXPLORE/FETCH_EXPLORE_PRODUCTS_REQUEST",
    "HOME/EXPLORE/FETCH_EXPLORE_PRODUCTS_SUCCESS",
    "HOME/EXPLORE/FETCH_EXPLORE_PRODUCTS_FAILURE"
  ),
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
  homeProducts: {
    isFetching: false,
    pageCount: 0,
    ids: [],
  },
  productConditions: {
    isFetching: false,
    ids: [],
  },
  exploreProducts: {
    isFetching: false,
    ids: [],
  },
};
/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const actions = {
  loadHomeProducts: () => {
    return (dispatch, getState) => {
      const { pageCount } = getState().home.products;
      const rowIndex = pageCount * params.PAGE_SIZE_HOME_PRODUCTS;
      const endpoint = url.getHomeProductsList(
        params.PATH_HOME_PRODUCTS,
        rowIndex,
        params.PAGE_SIZE_HOME_PRODUCTS
      );
      return dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_HOME_PRODUCTS.all(),
          endpoint,
          homeProductSchema
        )
      );
    };
  },
  loadProductConditions: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getProductConditions();
      return Object.keys(getState().entities.productConditions).length > 0
        ? null
        : await dispatch(
            dispatchAction(
              FETCH_DATA,
              types.FETCH_HOME_PRODUCT_CONDITIONS.all(),
              endpoint,
              productConditionSchema
            )
          );
    };
  },
  loadExploreProducts: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.getExplore(data);
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_EXPLORE_PRODUCTS.all(),
          endpoint,
          exploreProductsSchema
        )
      );
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const products = (state = initialState.homeProducts, action) => {
  switch (action.type) {
    case types.FETCH_HOME_PRODUCTS.request():
      return { ...state, isFetching: true };
    case types.FETCH_HOME_PRODUCTS.success():
      return {
        ...state,
        isFetching: false,
        pageCount: state.pageCount + 1,
        ids: action.response.ids,
      };
    case types.FETCH_HOME_PRODUCTS.failure():
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const productConditions = (state = initialState.productConditions, action) => {
  switch (action.type) {
    case types.FETCH_HOME_PRODUCT_CONDITIONS.request():
      return { ...state, isFetching: true };
    case types.FETCH_HOME_PRODUCT_CONDITIONS.success():
      return { isFetching: false, ids: action.response.ids };
    case types.FETCH_HOME_PRODUCT_CONDITIONS.failure():
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const exploreProducts = (state = initialState.exploreProducts, action) => {
  switch (action.type) {
    case types.FETCH_EXPLORE_PRODUCTS.request():
      return { ...state, isFetching: true };
    case types.FETCH_EXPLORE_PRODUCTS.success():
      return { ...state, isFetching: false, ids: action.response.ids };
    case types.FETCH_EXPLORE_PRODUCTS.failure():
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const reducer = combineReducers({
  products,
  productConditions,
  exploreProducts,
});

export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														   *
 * *********************************************************************************************************************/
export const getHomeProducts = (state) => {
  return state.home.products.ids.map((id) => {
    return state.entities.homeProducts[id];
  });
};

export const getProductConditions = (state) => {
  return state.home.productConditions.ids.map((id) => {
    return state.entities.productConditions[id];
  });
};

export const getExploreProducts = (state) => {
  return state.home.exploreProducts.ids.map((id) => {
    return state.entities.exploreProducts[id];
  });
};
