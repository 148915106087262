const bidSuccess = () => ({
  status: 200,
  data: {
    id: "231fwdweq231",
    bidPrice: 67.89,
    productId: "1",
    createdAt: "1 minute ago",
  },
  message: "You offered a price successfully!",
  error: "",
});

export default bidSuccess;
