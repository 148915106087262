import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Policy from "./Policy";
import { Formik } from "formik";
import * as Yup from "yup";
import { Box, FormHelperText } from "@material-ui/core";
import toast from "react-hot-toast";

const Login = ({ openSignIn, setOpenSignIn, login }) => {
  const handleClose = () => setOpenSignIn(false);

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        console.log(values);
        try {
          const res = await login(values.email, values.password);
          console.log(res);
          if (!res.error) {
            setStatus({ success: true });
            setSubmitting(false);
            toast.success("Welcome to MonkeyDock!");
            resetForm();
            handleClose();
          } else {
            setStatus({ success: false });
            setErrors({ email: res.message });
          }
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        resetForm,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Dialog
            fullWidth
            open={openSignIn}
            onClose={() => {
              handleClose();
              resetForm();
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Log in</DialogTitle>
            <DialogContent>
              <Typography
                variant={"h3"}
                color={"primary"}
                style={{ textAlign: "center", margin: "8px 0 8px 0" }}
              >
                MonkeyDock
              </Typography>
              {touched.email && errors.email && (
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.email}</FormHelperText>
                </Box>
              )}
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                name={"email"}
                fullWidth
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />

              {touched.password && errors.password && (
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.password}</FormHelperText>
                </Box>
              )}

              <TextField
                autoFocus
                margin="dense"
                id="password"
                label="password"
                type="password"
                name={"password"}
                fullWidth
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              <div>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    console.info("I'm a button.");
                  }}
                >
                  Forgot your password?
                </Link>
              </div>

              <Link
                component="button"
                variant="body1"
                onClick={() => {
                  console.info("I'm a button.");
                }}
              >
                Don't have an account? Sign up
              </Link>
              <div style={{ marginTop: 20 }}>
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  type={"submit"}
                  onClick={handleSubmit}
                  value={"submit"}
                >
                  Log in
                </Button>
              </div>
              <Policy />
              <Divider />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose();
                  resetForm();
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </Formik>
  );
};
export default Login;
