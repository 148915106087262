import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReceiptIcon from "@material-ui/icons/Receipt";
import useAuth_ from "../../hooks/useAuth_";
import BriefcaseIcon from "../../icons/Briefcase";
import CalendarIcon from "../../icons/Calendar";
import ChartPieIcon from "../../icons/ChartPie";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import ChatAltIcon from "../../icons/ChatAlt";
import ClipboardListIcon from "../../icons/ClipboardList";
import FolderOpenIcon from "../../icons/FolderOpen";
import MailIcon from "../../icons/Mail";
import ShareIcon from "../../icons/Share";
import ShoppingBagIcon from "../../icons/ShoppingBag";
import ShoppingCartIcon from "../../icons/ShoppingCart";
import UserIcon from "../../icons/User";
import UsersIcon from "../../icons/Users";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import useAuth from "../../hooks/useAuth";
import MetaMaskConnect from "../../icons/MetamaskConnect";
import Ethereum from "../../icons/ethereum.svg";
import MDC from "../../icons/mdc.svg";
import numeral from "numeral";
import useWalletConfigurator from "../../hooks/aboutWallet/useWalletConfigurator";
import useWallet from "../../hooks/aboutWallet/useWallet";
import CardMedia from "@material-ui/core/CardMedia";
import logo from "../../icons/Icon.png";

const sections = [
  {
    title: "General",
    items: [
      // {
      //   title: "Overview",
      //   path: "/dashboard",
      //   icon: <ChartSquareBarIcon fontSize="small" />,
      // },
      // {
      //   title: 'Analytics',
      //   path: '/dashboard/analytics',
      //   icon: <ChartPieIcon fontSize="small" />
      // },
      // {
      //   title: 'Finance',
      //   path: '/dashboard/finance',
      //   icon: <ShoppingBagIcon fontSize="small" />
      // },
      {
        title: "Account",
        path: "/dashboard/account",
        icon: <UserIcon fontSize="small" />,
      },
    ],
  },
  {
    title: "Management",
    items: [
      // {
      //   title: 'Customers',
      //   path: '/dashboard/customers',
      //   icon: <UsersIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/dashboard/customers'
      //     },
      //     {
      //       title: 'Details',
      //       path: '/dashboard/customers/1'
      //     },
      //     {
      //       title: 'Edit',
      //       path: '/dashboard/customers/1/edit'
      //     }
      //   ]
      // },
      {
        title: "Products",
        path: "/dashboard/products",
        icon: <ShoppingCartIcon fontSize="small" />,
      },
      {
        title: "My Bids",
        path: "/dashboard/mybids",
        icon: <LocalOfferIcon fontSize="small" />,
        children: [
          {
            title: "Waiting",
            path: "/dashboard/mybids",
          },
          {
            title: "Confirmation",
            path: "/dashboard/mybids/confirmation",
          },
        ],
      },
      {
        title: "Orders",
        icon: <FolderOpenIcon fontSize="small" />,
        path: "/dashboard/orders",
      },
      // {
      //   title: "Invoices",
      //   path: "/dashboard/invoices",
      //   icon: <ReceiptIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: "List",
      //       path: "/dashboard/invoices",
      //     },
      //     {
      //       title: "Details",
      //       path: "/dashboard/invoices/1",
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   title: 'Platforms',
  //   items: [
  //     {
  //       title: 'Projects',
  //       path: '/dashboard/projects',
  //       icon: <BriefcaseIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Browse',
  //           path: '/dashboard/projects/browse'
  //         },
  //         {
  //           title: 'Details',
  //           path: '/dashboard/projects/1'
  //         },
  //         {
  //           title: 'Create',
  //           path: '/dashboard/projects/new'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social',
  //       path: '/dashboard/social',
  //       icon: <ShareIcon fontSize="small" />,
  //       children: [
  //         {
  //           title: 'Profile',
  //           path: '/dashboard/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           path: '/dashboard/social/feed'
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    title: "Apps",
    items: [
      // {
      //   title: 'Kanban',
      //   path: '/dashboard/kanban',
      //   icon: <ClipboardListIcon fontSize="small" />
      // },
      // {
      //   title: 'Mail',
      //   path: '/dashboard/mail',
      //   icon: <MailIcon fontSize="small" />
      // },
      // {
      //   title: 'Chat',
      //   path: '/dashboard/chat',
      //   icon: <ChatAltIcon fontSize="small" />
      // },
      {
        title: "Calendar",
        path: "/dashboard/calendar",
        icon: <CalendarIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { username, email, profileImg, loginAuth } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  useWalletConfigurator();
  const {
    walletAccountGetter,
    isMetaMaskInstalledGetter,
    mdcBalanceGetter,
    ethBalanceGetter,
    walletConnector,
    mdcBalancePeeker,
    ethBalancePeeker,
  } = useWallet();

  // get MD Balance
  useEffect(() => {
    if (walletAccountGetter) {
      mdcBalancePeeker(walletAccountGetter);
      ethBalancePeeker(walletAccountGetter);
    }
  }, [walletAccountGetter]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
          }}
        >
          <Link to="/" component={RouterLink} underline={"none"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CardMedia
                component="img"
                sx={{ width: 45 }}
                image={logo}
                alt="logo"
              />
              <Typography variant={"h5"}>MonkeyDock</Typography>
            </Box>
          </Link>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account">
              <Avatar
                src={profileImg}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {username}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                <Link color="primary" component={RouterLink} to="/pricing">
                  {email}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        {/*connect wallet*/}
        <Box sx={{ p: 2, pt: 0 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to="/dashboard/account"></RouterLink>
            <img
              src={MetaMaskConnect}
              alt={"MetaMaskConnect"}
              style={{
                cursor: "pointer",
                height: 48,
                width: 48,
              }}
            />

            <Box sx={{ ml: 2 }}>
              {!isMetaMaskInstalledGetter && !walletAccountGetter && (
                <Typography variant="body2">
                  <Link
                    href={
                      "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
                    }
                    target="_blank"
                  >
                    Install MateMask
                  </Link>
                </Typography>
              )}
              {isMetaMaskInstalledGetter && walletAccountGetter ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row" },
                      alignItems: "center",
                      overflow: "hidden",
                      padding: "2px 5px 2px 5px",
                    }}
                  >
                    <Typography variant="body2">
                      {`${walletAccountGetter.slice(
                        0,
                        6
                      )}...${walletAccountGetter.slice(-6)}`}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row" },
                      alignItems: "center",
                      overflow: "hidden",
                      padding: "5px 5px 5px 5px",
                    }}
                  >
                    <img
                      src={Ethereum}
                      alt={"Ethereum icon"}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {ethBalanceGetter
                        ? numeral(ethBalanceGetter).format(`0,0.00`)
                        : "0.00"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row" },
                      alignItems: "center",
                      overflow: "hidden",
                      padding: "2px 5px 2px 5px",
                      width: "auto",
                    }}
                  >
                    <img
                      src={MDC}
                      alt={"Ethereum icon"}
                      style={{
                        height: 25,
                        width: 25,
                      }}
                    />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>
                      {mdcBalanceGetter
                        ? numeral(mdcBalanceGetter).format(`0,0.00`)
                        : "0.00"}
                    </Typography>
                  </Box>
                </>
              ) : isMetaMaskInstalledGetter ? (
                <>
                  <Button fullWidth onClick={walletConnector}>
                    <Typography variant={"body2"}>Connect to wallet</Typography>{" "}
                  </Button>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
