import { decode } from "../../utils/jwt";
import Web3 from "web3";
import { combineReducers } from "redux";
import {
  handleConnectWallet,
  handleGetETHBalance,
  handleGetMDCBalance,
  loadUserWeb3,
} from "../../utils/web3Instance";
import { dispatch } from "react-hot-toast";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
  CONFIGURE_WALLET: "WEB3/CONFIGURE_WALLET",
  CONFIGURE_WALLET_FAILURE: "WEB3/CONFIGURE_WALLET_FAILURE",
  CONNECT_WALLET: "WEB3/CONNECT_WALLET",
  PEEK_ETH_BALANCE: "WEB3/PEEK_ETH_BALANCE",
  PEEK_MDC_BALANCE: "WEB3/PEEK_MDC_BALANCE",
  CHANGE_WALLET_ACCOUNT: "WEB3/CHANGE_WALLET_ACCOUNT",
  CHANGE_CURRENT_CHAIN: "WEB3/CHANGE_CURRENT_CHAIN",
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
  isMetaMaskInstalled: true,
  walletAccount: null,
  currentChain: null,
  mdcBalance: null,
  ethBalance: null,
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  walletConfigurator: () => {
    return async (dispatch, getState) => {
      if (typeof window.ethereum !== "undefined") {
        const account = await loadUserWeb3();
        return await dispatch({
          type: types.CONFIGURE_WALLET,
          payload: account,
        });
      } else {
        console.log("[MetaMask:Installation:CHECK]: false");
        return await dispatch({ type: types.CONFIGURE_WALLET_FAILURE });
      }
    };
  },
  walletConnection: () => {
    return async (dispatch, getState) => {
      const account = await handleConnectWallet();
      return await dispatch({ type: types.CONNECT_WALLET, payload: account });
    };
  },

  peekETHBalance: (walletAccount) => {
    return async (dispatch, getState) => {
      const eth = await handleGetETHBalance(walletAccount);
      return await dispatch({ type: types.PEEK_ETH_BALANCE, payload: eth });
    };
  },
  peekMDCBalance: (walletAccount) => {
    return async (dispatch, getState) => {
      const balance = await handleGetMDCBalance(walletAccount);
      return await dispatch({ type: types.PEEK_MDC_BALANCE, payload: balance });
    };
  },
  walletAccountChange: (walletAccount) => {
    return async (dispatch, getState) => {
      console.log("[MetaMask:Account:CHANGED]: ", walletAccount);
      return await dispatch({
        type: types.CHANGE_WALLET_ACCOUNT,
        payload: walletAccount,
      });
    };
  },
  currentChainChange: (chainId) => {
    return async (dispatch, getState) => {
      console.log("[MetaMask:Chain:CHANGED]: ", chainId);
      return await dispatch({
        type: types.CHANGE_WALLET_ACCOUNT,
        payload: chainId,
      });
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const wallet = (state = initialState, action) => {
  switch (action.type) {
    case types.CONFIGURE_WALLET:
      return { ...state, walletAccount: action.payload };
    case types.CONFIGURE_WALLET_FAILURE:
      return { ...state, isMetaMaskInstalled: false };
    case types.CONNECT_WALLET:
      return { ...state, walletAccount: action.payload };
    case types.PEEK_MDC_BALANCE:
      return { ...state, mdcBalance: action.payload };
    case types.PEEK_ETH_BALANCE:
      return { ...state, ethBalance: action.payload };
    case types.CHANGE_WALLET_ACCOUNT:
      return { ...state, walletAccount: action.payload };
    case types.CHANGE_CURRENT_CHAIN:
      return { ...state, currentChain: action.payload };
    default:
      return state;
  }
};

const reducer = combineReducers({ wallet });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														   *
 * *********************************************************************************************************************/

export const getWalletAccount = (state) => {
  return state.wallet.wallet.walletAccount;
};

export const getIsMetaMaskInstalled = (state) => {
  return state.wallet.wallet.isMetaMaskInstalled;
};

export const getPeekMDCBalance = (state) => {
  return state.wallet.wallet.mdcBalance;
};

export const getPeekETHBalance = (state) => {
  return state.wallet.wallet.ethBalance;
};
