import { combineReducers } from "redux";
import entities from "./entities";
import app from "./app";
import home from "./home";
import authentication from "./authentication";
import productDetail from "./productDetail";
import categories from "./categories";
import dashboard from "./dashboard";
import parcels from "./parcels";
import wallet from "./wallet";
import innerContent from "./innerContent";
import Calendar from "./calendar";

import { reducer as calendarReducer } from "../../slices/calendar";
import { reducer as chatReducer } from "../../slices/chat";
import { reducer as kanbanReducer } from "../../slices/kanban";
import { reducer as mailReducer } from "../../slices/mail";

// combine all reducers

const rootReducer = combineReducers({
  entities,
  app,
  home,
  productDetail,
  authentication,
  categories,
  dashboard,
  parcels,
  wallet,
  innerContent,
  Calendar,
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
});

export default rootReducer;
