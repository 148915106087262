/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";

export const schema = {
  name: "myProducts",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = createReducer(schema.name);

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getMyProductById = (state, id) => {
  return state.entities.myProducts[id];
};
