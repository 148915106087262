import { createContext, useEffect, useMemo, useState } from "react";
import SettingsContext, {
  restoreSettings,
  storeSettings,
} from "./SettingsContext";

const tabs = [
  { label: "General", value: "general" },
  // { label: "Billing", value: "billing" },
  { label: "Notifications", value: "notifications" },
  { label: "Security", value: "security" },
];

const AccountTabsContext = createContext({
  tabs,
  currentTab: "general",
  setCurrentTab: () => {},
});

export const AccountTabsProvider = (props) => {
  const { children } = props;
  const [currentTab, setCurrentTab] = useState("general");
  const value = useMemo(
    () => ({ tabs, currentTab, setCurrentTab }),
    [currentTab]
  );
  useEffect(() => {
    setCurrentTab("general");
  }, []);
  return (
    <AccountTabsContext.Provider value={value}>
      {children}
    </AccountTabsContext.Provider>
  );
};

export default AccountTabsContext;
