export const baseUrl = () => {
  let env = process.env.REACT_APP_ENV;
  if (env === "production") {
    return process.env.REACT_APP_PROD_BASE;
  } else if (env === "development") {
    return process.env.REACT_APP_DEV_BASE;
  }
};

export const baseUrlImg = () => {
  let env = process.env.REACT_APP_ENV;
  if (env === "production") {
    return process.env.REACT_APP_PROD_IMG_BASE;
  } else if (env === "development") {
    return process.env.REACT_APP_DEV_IMG_BASE;
  }
};

export default {
  getHomeProductsList: (path, rowIndex, pageSize) => ({
    url: `${baseUrl()}/homeProducts?rowIndex=${rowIndex}&pageSize=${pageSize}`,
    isProtected: false,
  }),
  signIn: () => ({
    url: `${baseUrl()}/login`,
    isProtected: false,
  }),
  signUp: () => ({
    url: `${baseUrl()}/signup`,
    isProtected: false,
  }),
  phoneVerification: () => ({
    url: `${baseUrl()}/phone/verification`,
    isProtected: true,
  }),
  getCategories: () => ({
    url: `${baseUrl()}/categories`,
    isProtected: false,
  }),
  getParcels: () => ({
    url: `${baseUrl()}/dashboard/parcel`,
    isProtected: true,
  }),
  getProductConditions: () => ({
    url: `${baseUrl()}/conditions`,
    isProtected: false,
  }),
  getProductDetail: (productId) => ({
    url: `${baseUrl()}/homeProducts/product/${productId}`,
    isProtected: false,
  }),
  getRelatedProduct: (productId) => ({
    url: `${baseUrl()}/homeProducts/product/relatedProducts/${productId}`,
    isProtected: false,
  }),
  getMyBids: () => ({
    url: `${baseUrl()}/dashboard/mybids`,
    isProtected: true,
  }),
  getDidBid: (productId) => ({
    url: `${baseUrl()}/homeProducts/product/didBid/${productId}`,
    isProtected: true,
  }),
  postBid: (productId) => ({
    url: `${baseUrl()}/homeproducts/product/bid/${productId}`,
    isProtected: true,
  }),
  updateMyBid: () => ({
    url: `${baseUrl()}/dashboard/mybids/bid/update`,
    isProtected: true,
  }),
  deleteMyBid: () => ({
    url: `${baseUrl()}/dashboard/mybids/bid/delete`,
    isProtected: true,
  }),
  getMyProducts: () => ({
    url: `${baseUrl()}/dashboard/myproducts`,
    isProtected: true,
  }),
  getProductBids: (productId) => ({
    url: `${baseUrl()}/dashboard/myproducts/product/bids/${productId}`,
    isProtected: true,
  }),
  postProduct: () => ({
    url: `${baseUrl()}/product/post/`,
    isProtected: true,
  }),
  deleteProduct: (productId) => ({
    url: `${baseUrl()}/dashboard/myProducts/delete`,
    isProtected: true,
  }),
  getExplore: ({ name, category, tags }) => ({
    url: `${baseUrl()}/explore?${name ? `name=${name}` : ""}${
      category ? `&category_id=${category}` : ""
    }${tags ? `&tag_id=${tags}` : ""}`,
    isProtected: false,
  }),
  sendPaymentRequest: (productId) => ({
    url: `${baseUrl()}/dashboard/myproducts/product/bids/${productId}`,
    isProtected: true,
  }),
  getPaymentSecret: () => ({
    url: `${baseUrl()}/payments/secret`,
    isProtected: true,
  }),
  getPaymentCards: () => ({
    url: `${baseUrl()}/payments/retrieve/cards`,
    isProtected: true,
  }),
  createPaymentCard: () => ({
    url: `${baseUrl()}/payments/save`,
    isProtected: true,
  }),
  retrieveIsChargeEnabled: () => ({
    url: `${baseUrl()}/user/charges_enabled`,
    isProtected: true,
  }),
  stripeDashboard: () => ({
    url: `${baseUrl()}/payments/express/dashboard`,
    isProtected: true,
  }),
  makePayment: () => ({
    url: `${baseUrl()}/payments/pay`,
    isProtected: true,
  }),
  getOrders: () => ({
    url: `${baseUrl()}/dashboard/orders`,
    isProtected: true,
  }),
  getCheckUsername: (username) => ({
    url: `${baseUrl()}/signup/username_availability?username=${username}`,
    isProtected: false,
  }),
  getInnerContent: () => ({
    url: `/mock/innerContent/fetch.json`,
    isProtected: false,
  }),
  getCalendarEvents: () => ({
    url: `${baseUrl()}/calendars`,
    isProtected: true,
  }),
  createCalendarEvent: () => ({
    url: `${baseUrl()}/calendar/create`,
    isProtected: true,
  }),
  updateCalendarEvent: (eventId) => ({
    url: `${baseUrl()}/calendar/update/${eventId}`,
    isProtected: true,
  }),
  deleteCalendarEvent: (eventId) => ({
    url: `${baseUrl()}/calendar/delete/${eventId}`,
    isProtected: true,
  }),
};
