import React, { useState } from "react";
import Login from "./Login";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Policy from "./Policy";
import SignUp from "./SignUp";
import useAuth from "../../../../hooks/useAuth";
import PhoneForm from "./PhoneForm";
import CodeForm from "./CodeForm";

const Auth = ({ openAuth, setOpenAuth }) => {
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  // const [openPhoneForm, setOpenPhoneForm] = useState(true);
  // const [openCodeForm, setOpenCodeForm] = useState(false);
  const { loginAuth } = useAuth();

  const handleClose = () => {
    setOpenAuth(false);
  };

  return (
    <>
      <div>
        <Dialog
          fullWidth
          open={openAuth}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Log in / Sign up</DialogTitle>
          <DialogContent>
            <Typography
              variant={"h3"}
              color={"primary"}
              style={{ textAlign: "center", margin: "8px 0 8px 0" }}
            >
              MonkeyDock
            </Typography>

            <div style={{ marginTop: 20 }}>
              <Button
                fullWidth
                variant="contained"
                disableElevation
                onClick={() => {
                  setOpenSignIn(true);
                  setOpenAuth(false);
                }}
              >
                Log in
              </Button>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button
                fullWidth
                variant="outlined"
                disableElevation
                onClick={() => {
                  setOpenSignUp(true);
                  setOpenAuth(false);
                }}
              >
                Sign up
              </Button>
            </div>

            <Policy />
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Login
        openSignIn={openSignIn}
        setOpenSignIn={(isOpen) => setOpenSignIn(isOpen)}
        login={(email, password) => loginAuth.login(email, password)}
      />
      <SignUp
        openAuth={openSignUp}
        setOpenAuth={(isOpen) => setOpenSignUp(isOpen)}
        signup={(data) => loginAuth.signup(data)}
      />
      {/*<PhoneForm openAuth={openPhoneForm} setOpenAuth={(isOpen) => setOpenPhoneForm(isOpen)} />*/}
      {/*<CodeForm  openAuth={openCodeForm} setOpenAuth={(isOpen) => setOpenCodeForm(isOpen)}/>*/}
    </>
  );
};

export default Auth;
