import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Drawer,
  Link,
  Toolbar,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Logo from "./Logo";
import useFetchCategories from "../hooks/useFetchCategories";
import Skeleton from "@material-ui/core/Skeleton";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import CardMedia from "@material-ui/core/CardMedia";
import logo from "../icons/Icon.png";
import Typography from "@material-ui/core/Typography";
import NavSection from "./NavSection";

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { categoriesGetter } = useFetchCategories();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          width: 290,
        },
      }}
    >
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          p: 2,
        }}
      >
        <Link to="/" component={RouterLink} underline={"none"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CardMedia
              component="img"
              sx={{ width: 50 }}
              image={logo}
              alt="logo"
            />
            <Typography variant={"h5"}>MonkeyDock</Typography>
          </Box>
        </Link>

        {categoriesGetter.length > 0 && (
          <MenuList>
            {categoriesGetter.map((category) => (
              <Link
                component={RouterLink}
                to={`/explore?category=${category.id}`}
                state={{ category: category.id, categoryName: category.name }}
                underline="none"
                variant="body1"
                color={"textPrimary"}
              >
                <MenuItem>{category.name}</MenuItem>
              </Link>
            ))}
          </MenuList>
        )}
      </Box>
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default MainSidebar;
