import { actionTypesConstructor } from "../utils";
import dispatchAction from "../utils/dispatchAction";
import { FETCH_DATA } from "../middlewares/api";
import { schema } from "./entities/innerContent";
import url from "../../utils/url";
import { next } from "stylis";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
  FETCH_INNERCONTENT: actionTypesConstructor(
    "INNERCONTENT/INNERCONTENT_REQUEST",
    "INNERCONTENT/INNERCONTENT_SUCCESS",
    "INNERCONTENT/INNERCONTENT_FAILURE"
  ),
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
  innerContent: {
    isFetching: false,
    ids: [],
  },
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadInnerContent: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getInnerContent();
      return Object.keys(getState().entities.innerContent).length > 0
        ? null
        : await dispatch(
            dispatchAction(
              FETCH_DATA,
              types.FETCH_INNERCONTENT.all(),
              endpoint,
              schema,
              null
            )
          );
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const innerContent = (state = initialState.innerContent, action) => {
  switch (action.type) {
    case types.FETCH_INNERCONTENT.request():
      return { ...state, isFetching: true };
    case types.FETCH_INNERCONTENT.success():
      return { isFetching: false, ids: action.response.ids };
    case types.FETCH_INNERCONTENT.failure():
      return initialState.innerContent;
    default:
      return state;
  }
};
export default innerContent;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getInnerContent = (state, name, title) => {
  return state.innerContent.ids.length > 0
    ? state.innerContent.ids
        .map((id) => state.entities.innerContent[id])
        .filter((each) => each.name === name && each.title === title)
    : [];
};
