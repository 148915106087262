import React, { useCallback, useEffect, useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import GoogleAddressInput from "../../googleAddressInput";
import Grid from "@material-ui/core/Grid";
import { DropzoneDialogBase } from "material-ui-dropzone";
import Cropper from "react-easy-crop";

import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Policy from "./Policy";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { FormHelperText } from "@material-ui/core";
import toast from "react-hot-toast";
import { monkeydock } from "../../../../monkeydock";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import useAuth from "../../../../hooks/useAuth";
import useDebounce from "../../../../hooks/useDebounce";
import { types } from "../../../../redux/modules/authentication";

const steps = ["Basic Account", "Personal Info.", "Profile Picture"];
// const dogImgURL = "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";
const dogImgURL = monkeydock;

const SignUp = ({ openAuth, setOpenAuth, signup }) => {
  const theme = useTheme();
  const [userAddress, setUserAddress] = useState("");
  const [isUsernameTaken, setIsUsernameTaken] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [croppedImage, setCroppedImage] = useState(null);
  const [fileObjects, setFileObjects] = useState([]);
  const [userPhoto, setUserPhoto] = useState(dogImgURL);
  const [open, setOpen] = React.useState(false);
  const [crop, setCrop] = useState({
    aspect: 4 / 4,
    unit: "px",
    maxHeight: 400,
    maxWidth: 400,
  });
  const imgRef = useRef(null);
  const { loginAuth } = useAuth();

  const handleClose = () => setOpenAuth(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  console.log(userAddress);

  function getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / imgRef.current.imageRef.clientWidth;
    const scaleY = image.naturalHeight / imgRef.current.imageRef.clientHeight;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      resolve(canvas.toDataURL("image/png"));
    });
  }

  function dataURLtoFile(dataUrl, filename) {
    let arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const onCropComplete = useCallback(async () => {
    let image = new Image();
    image.src = userPhoto;
    await getCroppedImg(image, crop)
      .then((img) => setCroppedImage(img))
      .catch((err) => console.log(err));
  }, [userPhoto, crop]);

  const usernameValidator = useDebounce(async (username) => {
    try {
      const result = await loginAuth.checkUsername(username);
      if (result.type === types.CHECK_USERNAME_SUCCESS) {
        setIsUsernameTaken(false);
      } else {
        setIsUsernameTaken(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, 1000);

  const RenderFirst = ({
    handleBlur,
    handleChange,
    emailValue,
    passwordValue,
    emailTouched,
    emailErrors,
    passwordTouched,
    passwordErrors,
  }) => {
    return (
      <div>
        {emailTouched && emailErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{emailErrors}</FormHelperText>
          </Box>
        )}

        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email Address"
          type="email"
          name={"email"}
          fullWidth
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={emailValue}
        />
        {passwordTouched && passwordErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{passwordErrors}</FormHelperText>
          </Box>
        )}
        <TextField
          autoFocus
          margin="dense"
          id="password"
          label="password"
          type="password"
          fullWidth
          name={"password"}
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={passwordValue}
        />
        <Policy />
      </div>
    );
  };

  const RenderSecond = ({
    handleBlur,
    handleChange,
    username,
    firstname,
    lastname,
    phone,
    address,
    postalCode,
    usernameTouched,
    firstnameTouched,
    lastnameTouched,
    phoneTouched,
    addressTouched,
    usernameErrors,
    firstnameErrors,
    lastnameErrors,
    phoneErrors,
    addressErrors,
    postalCodeErrors,
  }) => {
    return (
      <div>
        {usernameErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{usernameErrors}</FormHelperText>
          </Box>
        )}
        {isUsernameTaken && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>
              Username has been taken or prohibited
            </FormHelperText>
          </Box>
        )}
        <Field
          component={TextField}
          autoFocus
          margin="dense"
          id="username"
          label="User name"
          type="username"
          name={"username"}
          fullWidth
          variant="outlined"
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            e.target.value.length >= 5 &&
              e.target.value.length <= 15 &&
              usernameValidator(e.target.value);
          }}
          value={username}
        />
        {firstnameErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{firstnameErrors}</FormHelperText>
          </Box>
        )}
        <TextField
          margin="dense"
          id="firstname"
          label="First name"
          name={"firstname"}
          fullWidth
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={firstname}
        />
        {lastnameErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{lastnameErrors}</FormHelperText>
          </Box>
        )}
        <TextField
          margin="dense"
          id="lastname"
          label="Last name"
          name={"lastname"}
          fullWidth
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={lastname}
        />
        {phoneErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{phoneErrors}</FormHelperText>
          </Box>
        )}
        <TextField
          margin="dense"
          id="phone"
          label="Phone"
          name={"phone"}
          type="phone"
          fullWidth
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={phone}
        />
        {addressTouched && addressErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{addressErrors}</FormHelperText>
          </Box>
        )}
        <GoogleAddressInput
          name={"address"}
          id={"address"}
          onChange={handleChange}
          setUserAddress={(value) => setUserAddress(value)}
          value={userAddress}
        />
        {postalCodeErrors && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>{postalCodeErrors}</FormHelperText>
          </Box>
        )}
        <TextField
          margin="dense"
          id="postalCode"
          label="Postal Code"
          name={"postalCode"}
          type="postalCode"
          fullWidth
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={postalCode}
        />
      </div>
    );
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        username: "",
        firstname: "",
        lastname: "",
        phone: "",
        address: "",
        postalCode: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("Email is required"),
        password: Yup.string().required("Password is required"),
        username: Yup.string()
          .required("User name is required")
          .min(5, "Must longer than 5 characters")
          .max(15, "Must less than 15 characters"),
        firstname: Yup.string().required("First name is required"),
        lastname: Yup.string().required("Last name is required"),
        phone: Yup.number().required("Phone is required"),
        address: Yup.string(),
        postalCode: Yup.string().required("Postal code is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        const data = {
          email: values.email,
          first_name: values.firstname,
          last_name: values.lastname,
          username: values.username,
          password: values.password,
          phone: values.phone,
          address: userAddress?.structured_formatting.main_text,
          city: userAddress?.terms[2].value,
          state: userAddress?.terms[3].value,
          country: userAddress?.terms[4].value,
          zip_code: values.postalCode,
          profile_image: dataURLtoFile(
            croppedImage,
            `${values.username}_profileImg.png`
          ),
          user_type: "personal_user",
        };
        console.log(data);
        let fd = new FormData();
        for (let each in data) {
          fd.append(each, data[each]);
        }

        try {
          const res = await loginAuth.signup(fd);
          if (!res.code) {
            setStatus({ success: true });
            setSubmitting(false);
            toast.success(res.message);
            resetForm();
            handleClose();
          } else {
            toast.error(res.message);
            setStatus({ success: false });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          toast.error("Something went wrong!");
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        resetForm,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <Dialog
          fullWidth
          open={openAuth}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Sign up</DialogTitle>
          <DialogContent>
            <Typography
              variant={"h3"}
              color={"primary"}
              style={{ textAlign: "center", margin: "8px 0 8px 0" }}
            >
              MonkeyDock
            </Typography>
            <Box sx={{ width: "100%" }}>
              <Stepper
                activeStep={activeStep}
                style={{ margin: "10px 0 10px 0" }}
              >
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re almost finished!
                  </Typography>

                  <Card
                    sx={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          User name: {values.username}
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          Email: {values.email}
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          First name: {values.firstname}
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          Last name: {values.lastname}
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          Phone: {values.phone}
                        </Typography>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          Address:{" "}
                          {`${userAddress?.description}, ${values.postalCode}`}
                        </Typography>
                      </CardContent>
                    </Box>
                    <CardMedia
                      sx={{ width: 200, height: 200, alignSelf: "center" }}
                      component="img"
                      image={croppedImage ?? userPhoto}
                    />
                  </Card>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button
                      color={"secondary"}
                      onClick={() => {
                        handleReset();
                        resetForm();
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type={"submit"}
                      onClick={handleSubmit}
                      value={"submit"}
                    >
                      Submit
                    </Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {activeStep === 0 && (
                    <RenderFirst
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      emailValue={values.email}
                      passwordValue={values.password}
                      emailTouched={touched.email}
                      emailErrors={errors.email}
                      passwordTouched={touched.password}
                      passwordErrors={errors.password}
                    />
                  )}
                  {activeStep === 1 && (
                    <RenderSecond
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      username={values.username}
                      firstname={values.firstname}
                      lastname={values.lastname}
                      phone={values.phone}
                      address={values.address}
                      postalCode={values.postalCode}
                      usernameTouched={touched.username}
                      firstnameTouched={touched.firstname}
                      lastnameTouched={touched.lastname}
                      phoneTouched={touched.phone}
                      addressTouched={touched.address}
                      usernameErrors={errors.username}
                      firstnameErrors={errors.firstname}
                      lastnameErrors={errors.lastname}
                      phoneErrors={errors.phone}
                      addressErrors={errors.address}
                      postalCodeErrors={errors.postalCode}
                    />
                  )}
                  {/*image corp zone*/}
                  {activeStep === 2 && (
                    <>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          marginBottom: theme.spacing(2),
                        }}
                        color="primary"
                        onClick={() => setOpen(true)}
                      >
                        Set Profile Image
                      </Button>

                      <DropzoneDialogBase
                        acceptedFiles={["image/*"]}
                        fileObjects={fileObjects}
                        cancelButtonText={"cancel"}
                        submitButtonText={"submit"}
                        maxFileSize={5000000}
                        open={open}
                        onAdd={(newFileObjs) => {
                          console.log("onAdd", newFileObjs);
                          setFileObjects(newFileObjs);
                          setUserPhoto(newFileObjs[0].data);
                        }}
                        onDelete={(deleteFileObj) => {
                          console.log("onDelete", deleteFileObj);
                          setFileObjects([]);
                        }}
                        onClose={() => setOpen(false)}
                        onSave={() => {
                          console.log("onSave", fileObjects);
                          setOpen(false);
                        }}
                        showAlerts={false}
                        showPreviews={true}
                        showFileNamesInPreview={true}
                      />
                      <ReactCrop
                        src={userPhoto}
                        crop={crop}
                        ref={imgRef}
                        onChange={(newCrop) => {
                          setCrop(newCrop);
                        }}
                        onDragEnd={onCropComplete}
                        circularCrop
                      />
                    </>
                  )}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    <Box sx={{ flex: "1 1 auto" }} />

                    <Button onClick={handleNext}>
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </Box>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                resetForm();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
export default React.memo(SignUp);
