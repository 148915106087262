/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";
import { baseUrl } from "../../../utils/url";
import { getCreatedAt } from "../../../utils/getCreatedAt";
import { types } from "../dashboard";

export const schema = {
  name: "myBids",
  id: "bidId",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.UPDATE_DASHBOARD_MY_BID.success():
      return {
        ...state,
        [action.response.data.didBid.bidId]: {
          ...state[action.response.data.didBid.bidId],
          myBid: action.response.data.didBid.bidPrice,
        },
      };
    case types.FETCH_DASHBOARD_MY_BIDS.success():
      return { ...state, ...action.response[schema.name] };
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getMyBid = (state, bidId) => {
  return state.dashboard.myBids.ids.length > 0 && bidId.length > 0
    ? {
        ...state.entities.myBids[bidId],
        createdAt: getCreatedAt(state.entities.myBids[bidId].createAt),
        productImage: baseUrl() + state.entities.myBids[bidId].productImage,
      }
    : [];
};
