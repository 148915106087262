import { useDispatch } from "react-redux";
import useMounted from "../useMounted";
import { actions as WalletConfiguratorActions } from "../../redux/modules/wallet";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import useWallet from "./useWallet";

const useWalletConfigurator = () => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const web3provider = window.ethereum;
  const { walletAccountChanger, currentChainChanger } = useWallet();

  const walletConfiguratorDispatcher = bindActionCreators(
    WalletConfiguratorActions,
    dispatch
  );

  const walletConfigurator = async () => {
    const res = await walletConfiguratorDispatcher.walletConfigurator();
    console.log(res);
  };

  const handleAccountsChanged = (accounts) => {
    walletAccountChanger(accounts[0]);
  };

  const handleChainChanged = (chainId) => {
    currentChainChanger(chainId);
  };

  useEffect(() => {
    if (mounted.current) {
      walletConfigurator();
      if (typeof web3provider !== "undefined") {
        web3provider.on("accountsChanged", handleAccountsChanged);
        web3provider.on("chainChanged", handleChainChanged);
      }

      return () => {
        web3provider.removeListener("accountsChanged", handleAccountsChanged);
        web3provider.removeListener("chainChanged", handleChainChanged);
      };
    }
  }, [mounted]);
};

export default useWalletConfigurator;
