import Web3 from "web3";

const web3provider = window.ethereum;
const web3 = new Web3(window.ethereum);

export const loadUserWeb3 = async () => {
  const account = web3.eth.getAccounts().then((addr) => {
    console.log("[MetaMask:Account:LOADED]: ", addr);
    return addr[0];
  });

  return await account;
};

export const handleConnectWallet = async () => {
  console.log("[MetaMask:Connection]: Connecting MetaMask...");

  const accounts = await web3provider.request({
    method: "eth_requestAccounts",
  });
  const account = accounts[0];
  console.log("[MetaMask:Account:CONNECTED]: ", account);
  return account;
};

export const handleGetMDCBalance = async (walletAccount) => {
  const tokenABI = [
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
  ];
  const tokenInst = new web3.eth.Contract(
    tokenABI,
    "0x20d3FDEfDB8Fc0F7059c48781AEc23C1074eB299"
  );
  const balance = await tokenInst.methods.balanceOf(walletAccount).call();

  console.log("[MetaMask:Account:MDC BALANCE]: ", balance / Math.pow(10, 18));
  return balance / Math.pow(10, 18);
};

export const handleGetETHBalance = async (walletAccount) => {
  const balance = await web3provider.request({
    method: "eth_getBalance",
    params: [walletAccount, "latest"],
  });

  const wei = parseInt(balance, 16);
  console.log("[MetaMask:Account:ETH BALANCE]: ", wei / Math.pow(10, 18));
  return wei / Math.pow(10, 18);
};
