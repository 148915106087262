/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import { FETCH_DATA } from "../middlewares/api";
import { combineReducers } from "redux";
import { schema } from "./entities/categories";
import url from "../../utils/url";

export const types = {
  FETCH_HOME_CATEGORIES_REQUEST: "HOME/FETCH_HOME_CATEGORIES_REQUEST",
  FETCH_HOME_CATEGORIES_SUCCESS: "HOME/FETCH_HOME_CATEGORIES_SUCCESS",
  FETCH_HOME_CATEGORIES_FAILURE: "HOME/FETCH_HOME_CATEGORIES_FAILURE",
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
  homeCategories: {
    isFetching: false,
    ids: [],
  },
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadCategories: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getCategories();
      return Object.keys(getState().entities.categories).length > 0
        ? null
        : await dispatch(fetchCategories(endpoint));
    };
  },
};

const fetchCategories = (endpoint) => ({
  [FETCH_DATA]: {
    types: [
      types.FETCH_HOME_CATEGORIES_REQUEST,
      types.FETCH_HOME_CATEGORIES_SUCCESS,
      types.FETCH_HOME_CATEGORIES_FAILURE,
    ],
    schema,
    endpoint,
  },
});

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const homeCategories = (state = initialState.homeCategories, action) => {
  switch (action.type) {
    case types.FETCH_HOME_CATEGORIES_REQUEST:
      return { ...state, isFetching: true };
    case types.FETCH_HOME_CATEGORIES_SUCCESS:
      return { ...state, isFetching: false, ids: action.response.ids };
    case types.FETCH_HOME_CATEGORIES_FAILURE:
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const reducer = combineReducers({ homeCategories });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														   *
 * *********************************************************************************************************************/
export const getCategories = (state) => {
  return state.categories.homeCategories.ids.map((id) => {
    return state.entities.categories[id];
  });
};

export const getCategory = (state, categoryId) => {
  return categoryId
    ? state.entities.categories[categoryId]
    : state.entities.categories[state.categories.homeCategories.ids[0]];
};

export const getTags = (state, categoryId) => {
  return (
    categoryId &&
    state.entities.categories[categoryId] &&
    state.entities.categories[categoryId].tags
  );
};
