import { actionTypesConstructor } from "../utils";
import dispatchAction from "../utils/dispatchAction";
import { FETCH_DATA } from "../middlewares/api";
import { schema } from "./entities/parcels";
import url from "../../utils/url";
import { combineReducers } from "redux";

/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
  FETCH_DASHBOARD_PARCELS: actionTypesConstructor(
    "DASHBOARD/FETCH_DASHBOARD_PARCELS_REQUEST",
    "DASHBOARD/FETCH_DASHBOARD_PARCELS_SUCCESS",
    "DASHBOARD/FETCH_DASHBOARD_PARCELS_FAILURE"
  ),
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
  parcels: {
    isFetching: false,
    ids: [],
  },
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadParcels: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getParcels();
      return Object.keys(getState().entities.parcels).length > 0
        ? null
        : await dispatch(
            dispatchAction(
              FETCH_DATA,
              types.FETCH_DASHBOARD_PARCELS.all(),
              endpoint,
              schema,
              null
            )
          );
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const parcels = (state = initialState.parcels, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_PARCELS.request():
      return { ...state, isFetching: true };
    case types.FETCH_DASHBOARD_PARCELS.success():
      return { isFetching: false, ids: action.response.ids };
    case types.FETCH_DASHBOARD_PARCELS.failure():
      return initialState.parcels;
    default:
      return state;
  }
};
export default parcels;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getParcel = (state) => {
  return state.parcels.ids.length > 0
    ? state.parcels.ids.map((id) => state.entities.parcels[id])
    : [];
};
