/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import { FETCH_DATA, POST_DATA } from "../middlewares/api";
import { combineReducers } from "redux";
import { schema as calendarSchema } from "./entities/calendar";
import url from "../../utils/url";
import { actionTypesConstructor, dispatchAction } from "../utils";

export const types = {
  OPEN_CALENDAR_MODAL: "DASHBOARD/CALENDAR_MODAL/OPEN",
  CLOSE_CALENDAR_MODAL: "DASHBOARD/CALENDAR_MODAL/CLOSE",
  SELECT_EVENT: "DASHBOARD/CALENDAR/SELECT_EVENT",
  SELECT_RANGE: "DASHBOARD/CALENDAR/SELECT_RANGE",

  FETCH_DASHBOARD_CALENDAR_EVENTS: actionTypesConstructor(
    "DASHBOARD/FETCH_DASHBOARD_CALENDAR_EVENTS_REQUEST",
    "DASHBOARD/FETCH_DASHBOARD_CALENDAR_EVENTS_SUCCESS",
    "DASHBOARD/FETCH_DASHBOARD_CALENDAR_EVENTS_FAILED"
  ),
  CREATE_DASHBOARD_CALENDAR_EVENT: actionTypesConstructor(
    "DASHBOARD/CREATE_DASHBOARD_CALENDAR_EVENT_REQUEST",
    "DASHBOARD/CREATE_DASHBOARD_CALENDAR_EVENT_SUCCESS",
    "DASHBOARD/CREATE_DASHBOARD_CALENDAR_EVENT_FAILED"
  ),
  DELETE_DASHBOARD_CALENDAR_EVENT: actionTypesConstructor(
    "DASHBOARD/DELETE_DASHBOARD_CALENDAR_EVENT_REQUEST",
    "DASHBOARD/DELETE_DASHBOARD_CALENDAR_EVENT_SUCCESS",
    "DASHBOARD/DELETE_DASHBOARD_CALENDAR_EVENT_FAILED"
  ),
  UPDATE_DASHBOARD_CALENDAR_EVENT: actionTypesConstructor(
    "DASHBOARD/UPDATE_DASHBOARD_CALENDAR_EVENT_REQUEST",
    "DASHBOARD/UPDATE_DASHBOARD_CALENDAR_EVENT_SUCCESS",
    "DASHBOARD/UPDATE_DASHBOARD_CALENDAR_EVENT_FAILED"
  ),
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
  isFetching: false,
  isPosting: false,
  ids: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadCalendarEvents: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getCalendarEvents();
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_DASHBOARD_CALENDAR_EVENTS.all(),
          endpoint,
          calendarSchema
        )
      );
    };
  },
  openModal: () => {
    return async (dispatch, getState) => {
      return await dispatch({ type: types.OPEN_CALENDAR_MODAL, payload: true });
    };
  },
  closeModal: () => {
    return async (dispatch, getState) => {
      return await dispatch({
        type: types.CLOSE_CALENDAR_MODAL,
        payload: false,
      });
    };
  },
  selectEvent: (eventId) => {
    return async (dispatch, getState) => {
      return await dispatch({ type: types.SELECT_EVENT, payload: eventId });
    };
  },
  selectRange: (start, end) => {
    return async (dispatch, getState) => {
      return await dispatch({
        type: types.SELECT_RANGE,
        payload: { start, end },
      });
    };
  },
  createEvent: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.createCalendarEvent();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.CREATE_DASHBOARD_CALENDAR_EVENT.all(),
          endpoint,
          calendarSchema,
          data
        )
      );
    };
  },
  updateEvent: ({ eventId, data }) => {
    return async (dispatch, getState) => {
      const endpoint = url.updateCalendarEvent(eventId);
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.UPDATE_DASHBOARD_CALENDAR_EVENT.all(),
          endpoint,
          null,
          data
        )
      );
    };
  },
  deleteEvent: ({ eventId, data }) => {
    return async (dispatch, getState) => {
      const endpoint = url.deleteCalendarEvent(eventId);
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.DELETE_DASHBOARD_CALENDAR_EVENT.all(),
          endpoint,
          null,
          data
        )
      );
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const Calendar = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_CALENDAR_EVENTS.request():
      return { ...state, isFetching: true };
    case types.FETCH_DASHBOARD_CALENDAR_EVENTS.success():
      return { ...state, isFetching: false, ids: action.response.ids };
    case types.FETCH_DASHBOARD_CALENDAR_EVENTS.failure():
      return { ...state, isFetching: false };
    case types.CREATE_DASHBOARD_CALENDAR_EVENT.request():
      return { ...state, isFetching: true };
    case types.CREATE_DASHBOARD_CALENDAR_EVENT.success():
      return {
        ...state,
        isFetching: false,
        ids: state.ids.concat(action.response.ids),
      };
    case types.CREATE_DASHBOARD_CALENDAR_EVENT.failure():
      return { ...state, isFetching: false };
    case types.DELETE_DASHBOARD_CALENDAR_EVENT.request():
      return { ...state, isFetching: true };
    case types.DELETE_DASHBOARD_CALENDAR_EVENT.success():
      return {
        ...state,
        isFetching: false,
        ids: state.ids.filter((each) => each !== action.payload),
      };
    case types.DELETE_DASHBOARD_CALENDAR_EVENT.failure():
      return { ...state, isFetching: false };
    case types.UPDATE_DASHBOARD_CALENDAR_EVENT.request():
      return { ...state, isFetching: true };
    case types.UPDATE_DASHBOARD_CALENDAR_EVENT.success():
      return { ...state, isFetching: false };
    case types.UPDATE_DASHBOARD_CALENDAR_EVENT.failure():
      return { ...state, isFetching: false };
    case types.OPEN_CALENDAR_MODAL:
      return { ...state, isModalOpen: action.payload };
    case types.CLOSE_CALENDAR_MODAL:
      return {
        ...state,
        isModalOpen: action.payload,
        selectedRange: null,
        selectedEventId: null,
      };
    case types.SELECT_EVENT:
      return { ...state, selectedEventId: action.payload };
    case types.SELECT_RANGE:
      return { ...state, selectedRange: action.payload };
    default:
      return state;
  }
};

export default Calendar;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getCalendarEvents = (state) => {
  return state.Calendar.ids.length > 0
    ? state.Calendar.ids.map((id) => state.entities.calendar[id])
    : [];
};

export const getCalendarSelectedEvent = (state, eventId) => {
  return state.Calendar.ids.length > 0 && eventId
    ? state.entities.calendar[eventId]
    : null;
};

export const getIsModalOpen = (state) => {
  return state.Calendar?.isModalOpen;
};

export const getSelectedEventId = (state) => {
  return state.Calendar?.selectedEventId;
};

export const getSelectedRange = (state) => {
  return state.Calendar.selectedRange;
};
