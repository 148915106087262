const createReducer = (name) => {
  return (state = {}, action) => {
    console.log(action);
    if (action.response && action.response[name]) {
      return { ...state, ...action.response[name] };
    }
    return state;
  };
};

export default createReducer;
