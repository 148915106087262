import { FETCH_DATA, POST_DATA } from "../middlewares/api";
import { combineReducers } from "redux";
import {
  schema,
  relatedProductsSchema,
  didBidSchema,
} from "./entities/productDetail";
import url, { baseUrlImg } from "../../utils/url";
import { formatRelative, subDays } from "date-fns";
import { getCreatedAt } from "../../utils/getCreatedAt";
/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
export const types = {
  FETCH_PRODUCT_DETAIL_REQUEST: "HOME/ITEMDETAIL/FETCH_PRODUCT_DETAILS_REQUEST",
  FETCH_PRODUCT_DETAIL_SUCCESS: "HOME/ITEMDETAIL/FETCH_PRODUCT_DETAILS_SUCCESS",
  FETCH_PRODUCT_DETAIL_FAILURE: "HOME/ITEMDETAIL/FETCH_PRODUCT_DETAILS_FAILURE",

  FETCH_RELATED_PRODUCTS_REQUEST:
    "HOME/ITEMDETAIL/FETCH_RELATED_PRODUCTS_REQUEST",
  FETCH_RELATED_PRODUCTS_SUCCESS:
    "HOME/ITEMDETAIL/FETCH_RELATED_PRODUCTS_SUCCESS",
  FETCH_RELATED_PRODUCTS_FAILURE:
    "HOME/ITEMDETAIL/FETCH_RELATED_PRODUCTS_FAILURE",

  POST_BID_REQUEST: "HOME/ITEMDETAIL/POST_BID_REQUEST",
  POST_BID_SUCCESS: "HOME/ITEMDETAIL/POST_BID_SUCCESS",
  POST_BID_FAILURE: "HOME/ITEMDETAIL/POST_BID_FAILURE",

  FETCH_DID_BID_REQUEST: "HOME/ITEMDETAIL/FETCH_DID_BID_REQUEST",
  FETCH_DID_BID_SUCCESS: "HOME/ITEMDETAIL/FETCH_DID_BID_SUCCESS",
  FETCH_DID_BID_FAILURE: "HOME/ITEMDETAIL/FETCH_DID_BID_FAILURE",
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/

const initialState = {
  isFetching: false,
  isPosting: false,
  id: null,
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadProductDetail: (productId) => {
    return async (dispatch, getState) => {
      const endpoint = url.getProductDetail(productId);
      return await dispatch(fetchProductDetail(endpoint));
    };
  },
  loadRelatedProducts: (productId) => {
    return async (dispatch, getState) => {
      const endpoint = url.getRelatedProduct(productId);
      return await dispatch(fetchRelatedProducts(endpoint));
    };
  },
  postProductBid: (data, productId) => {
    return async (dispatch, getState) => {
      const endpoint = url.postBid(productId);
      return await dispatch(postProductBid(endpoint, data));
    };
  },
  loadDidBid: (productId) => {
    return async (dispatch, getState) => {
      const endpoint = url.getDidBid(productId);
      return await dispatch(fetchDidBid(endpoint));
    };
  },
};

const fetchProductDetail = (endpoint) => ({
  [FETCH_DATA]: {
    types: [
      types.FETCH_PRODUCT_DETAIL_REQUEST,
      types.FETCH_PRODUCT_DETAIL_SUCCESS,
      types.FETCH_PRODUCT_DETAIL_FAILURE,
    ],
    endpoint,
    schema,
  },
});

const fetchRelatedProducts = (endpoint) => ({
  [FETCH_DATA]: {
    types: [
      types.FETCH_RELATED_PRODUCTS_REQUEST,
      types.FETCH_RELATED_PRODUCTS_SUCCESS,
      types.FETCH_RELATED_PRODUCTS_FAILURE,
    ],
    endpoint,
    schema: relatedProductsSchema,
  },
});

const postProductBid = (endpoint, data) => ({
  [POST_DATA]: {
    types: [
      types.POST_BID_REQUEST,
      types.POST_BID_SUCCESS,
      types.POST_BID_FAILURE,
    ],
    endpoint,
    data,
    schema: didBidSchema,
  },
});

const fetchDidBid = (endpoint) => ({
  [FETCH_DATA]: {
    types: [
      types.FETCH_DID_BID_REQUEST,
      types.FETCH_DID_BID_SUCCESS,
      types.FETCH_DID_BID_FAILURE,
    ],
    endpoint,
    schema: didBidSchema,
  },
});

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const productDetail = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PRODUCT_DETAIL_REQUEST:
      return { ...state, isFetching: true };
    case types.POST_BID_REQUEST:
      return { ...state, isPosting: true };
    case types.FETCH_PRODUCT_DETAIL_SUCCESS:
      return { ...state, isFetching: false, id: action.response.ids[0] };
    case types.POST_BID_SUCCESS:
      return { ...state, isPosting: false };
    case types.FETCH_PRODUCT_DETAIL_FAILURE:
      return initialState;
    case types.POST_BID_FAILURE:
      return initialState;
    default:
      return state;
  }
};

const reducer = combineReducers({ productDetail });
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														   *
 * *********************************************************************************************************************/

export const getProductInfo = (state) => {
  if (state.productDetail.productDetail.id) {
    const info =
      state.entities.productDetail[state.productDetail.productDetail.id];

    return {
      name: info.name,
      images: info.images.map((img) => baseUrlImg() + img),
      condition: info.condition,
      category: info.category,
      address: info.address,
      totalBids: info.totalBids,
      createdAt: formatRelative(
        subDays(new Date(info.createdAt), 0),
        new Date()
      ),
      description: info.description,
      didBid: info.didBid,
      tags: info.tags,
    };
  }
  return null;
};

export const getProductDidBid = (state) => {
  if (state.productDetail.productDetail.id) {
    const didBid = state.entities.productDetail?.didBid;
    return didBid && Object.values(didBid).length > 0
      ? {
          ...Object.values(didBid)[0],
          createdAt: getCreatedAt(Object.values(didBid)[0].createdAt),
        }
      : null;
  }
  return null;
};

export const getProductPostedBy = (state) => {
  if (state.productDetail.productDetail.id) {
    const info =
      state.entities.productDetail[state.productDetail.productDetail.id];
    const profileImg = baseUrlImg() + info.postedBy.profileImg;
    return {
      ...info.postedBy,
      profileImg,
      createdAt: getCreatedAt(info.postedBy.createdAt),
    };
  }
  return null;
};

export const getRelatedProducts = (state) => {
  return state.entities.productDetail.relatedProducts
    ? Object.values(state.entities.productDetail.relatedProducts)
    : [];
};

export const getOtherProducts = (state) => {
  if (state.productDetail.productDetail.id) {
    const info =
      state.entities.productDetail[state.productDetail.productDetail.id];
    return info.postedBy.products;
  }
  return null;
};

export const isFetchingProductDetail = (state) => {
  return state.productDetail.productDetail.isFetching;
};
