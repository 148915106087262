import React, { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer, Link } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Logo from "../Logo";
import NavSection from "../NavSection";
import CardMedia from "@material-ui/core/CardMedia";
import logo from "../../icons/Icon.png";
import Typography from "@material-ui/core/Typography";

const sections = [
  {
    title: "Terms Of Service",
    items: [
      {
        title: "Introduction",
        path: "/docs/TermsOfService/introduction",
      },
      {
        title: "Privacy",
        path: "/docs/TermsOfService/privacy",
      },
      {
        title: "User Accounts",
        path: "/docs/TermsOfService/userAccounts",
      },
      {
        title: "Payment and Purchases",
        path: "/docs/TermsOfService/paymentAndPurchases",
      },
      {
        title: "Services",
        path: "/docs/TermsOfService/services",
      },
      {
        title: "Shipping",
        path: "/docs/TermsOfService/shipping",
      },
      {
        title: "Rules and Restrictions",
        path: "/docs/TermsOfService/rulesAndRestrictions",
      },
      {
        title: "Indemnity",
        path: "/docs/TermsOfService/indemnity",
      },
      {
        title: "Content and Licenses",
        path: "/docs/TermsOfService/contentAndLicenses",
      },
      {
        title: "User Content",
        path: "/docs/TermsOfService/userContent",
      },
      {
        title: "Ownership",
        path: "/docs/TermsOfService/ownership",
      },
      {
        title: "Limitation of Liability",
        path: "/docs/TermsOfService/limitationOfLiability",
      },
      {
        title: "Feedback",
        path: "/docs/TermsOfService/feedback",
      },
      {
        title: "Experimental and Beta Services",
        path: "/docs/TermsOfService/experimentalAndBetaServices",
      },
      {
        title: "Arbitration",
        path: "/docs/TermsOfService/arbitration",
      },
      {
        title: "Limitation of Liability",
        path: "/docs/TermsOfService/provisions",
      },
    ],
  },
];

const DocsSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        sx={{
          display: {
            lg: "none",
          },
          p: 2,
        }}
      >
        <Link to="/" component={RouterLink} underline={"none"}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CardMedia
              component="img"
              sx={{ width: 45 }}
              image={logo}
              alt="logo"
            />
            <Typography variant={"h5"}>MonkeyDock</Typography>
          </Box>
        </Link>
      </Box>
      <Box p={2}>
        {sections.map((section) => (
          <NavSection
            key={section.title}
            pathname={location.pathname}
            sx={{
              "& + &": {
                mt: 3,
              },
            }}
            {...section}
          />
        ))}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        variant="permanent"
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !important",
            width: 256,
          },
        }}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          width: 256,
        },
      }}
    >
      {content}
    </Drawer>
  );
};

DocsSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DocsSidebar;
