/***********************************************************************************************************************
 * 													SCHEMA 														       *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";
import { types } from "../dashboard";

export const schema = {
  name: "productBids",
  id: "bidId",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 ***********************************************************************************************************************/

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_PRODUCT_BIDS.success():
      return { ...state, ...action.response[schema.name] };
    case types.POST_PAYMENT_REQUEST.success():
      const paymentRequestDate = action.response.data.paymentRequestDate;
      const callbackIds = action.response.data.bidIds;
      let productBids = state;
      Object.keys(productBids).forEach((each) => {
        if (callbackIds.includes(each)) {
          productBids[each].isConfirmedBySeller = true;
          productBids[each].paymentRequestDate = paymentRequestDate;
        }
      });
      return productBids;
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
