import createReducer from "../../../utils/createReducer";

/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
export const exploreProductsSchema = {
  name: "exploreProducts",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = createReducer(exploreProductsSchema.name);

export default reducer;
