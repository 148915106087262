/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";
import { types } from "../home";

export const schema = {
  name: "homeProducts",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_HOME_PRODUCTS.success():
      return { ...state, ...action.response[schema.name] };
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const getProductById = (state, id) => {
  return state.entities.homeProducts[id];
};
