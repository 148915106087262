/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";
import { combineReducers } from "redux";
import { types } from "../productDetail";

export const schema = {
  name: "productDetail",
  id: "productId",
};

export const relatedProductsSchema = {
  name: "relatedProducts",
  id: "productId",
};

export const didBidSchema = {
  name: "didBid",
  id: "bidId",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.POST_BID_SUCCESS:
      return {
        ...state,
        [didBidSchema.name]: action.response[didBidSchema.name],
      };

    case types.FETCH_DID_BID_SUCCESS:
      return {
        ...state,
        [didBidSchema.name]: action.response[didBidSchema.name],
      };

    case types.FETCH_RELATED_PRODUCTS_SUCCESS:
      return {
        ...state,
        [relatedProductsSchema.name]:
          action.response[relatedProductsSchema.name],
      };

    case types.FETCH_PRODUCT_DETAIL_SUCCESS:
      return { ...state, ...action.response[schema.name] };
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
