/***********************************************************************************************************************
 * 													SCHEMA 														       *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";

export const schema = {
  name: "conditions",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 ***********************************************************************************************************************/

const reducer = createReducer(schema.name);

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
