import { useDispatch, useSelector } from "react-redux";
import {
  actions as WalletConfiguratorActions,
  getIsMetaMaskInstalled,
  getPeekETHBalance,
  getPeekMDCBalance,
  getWalletAccount,
} from "../../redux/modules/wallet";
import { bindActionCreators } from "redux";

const useWallet = () => {
  const dispatch = useDispatch();
  const walletConfiguratorDispatcher = bindActionCreators(
    WalletConfiguratorActions,
    dispatch
  );
  const walletAccountGetter = useSelector((state) => getWalletAccount(state));
  const isMetaMaskInstalledGetter = useSelector((state) =>
    getIsMetaMaskInstalled(state)
  );
  const mdcBalanceGetter = useSelector((state) => getPeekMDCBalance(state));
  const ethBalanceGetter = useSelector((state) => getPeekETHBalance(state));

  return {
    walletAccountGetter,
    isMetaMaskInstalledGetter,
    mdcBalanceGetter,
    ethBalanceGetter,
    walletConnector: () => walletConfiguratorDispatcher.walletConnection(),
    mdcBalancePeeker: (walletAccount) =>
      walletConfiguratorDispatcher.peekMDCBalance(walletAccount),
    ethBalancePeeker: (walletAccount) =>
      walletConfiguratorDispatcher.peekETHBalance(walletAccount),
    walletAccountChanger: (walletAccount) =>
      walletConfiguratorDispatcher.walletAccountChange(walletAccount),
    currentChainChanger: (chainId) =>
      walletConfiguratorDispatcher.currentChainChange(chainId),
  };
};

export default useWallet;
