import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Link, Toolbar } from "@material-ui/core";
import MenuIcon from "../../icons/Menu";
import Logo from "../Logo";
import CardMedia from "@material-ui/core/CardMedia";
import logo from "../../icons/Icon.png";
import Typography from "@material-ui/core/Typography";
import React from "react";

const DocsNavbar = ({ onSidebarMobileOpen }) => (
  <AppBar
    sx={{
      backgroundColor: "background.paper",
      color: "text.primary",
      boxShadow: "none",
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      zIndex: (theme) => theme.zIndex.drawer + 100,
    }}
  >
    <Toolbar sx={{ height: 64 }}>
      <IconButton
        color="inherit"
        onClick={onSidebarMobileOpen}
        sx={{
          display: {
            lg: "none",
          },
        }}
      >
        <MenuIcon fontSize="small" />
      </IconButton>
      <Link
        to="/"
        component={RouterLink}
        underline={"none"}
        sx={{
          display: {
            lg: "inline",
            xs: "none",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CardMedia
            component="img"
            sx={{ width: 50 }}
            image={logo}
            alt="logo"
          />
          <Typography variant={"h5"}>MonkeyDock</Typography>
        </Box>
      </Link>
    </Toolbar>
  </AppBar>
);

DocsNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DocsNavbar;
