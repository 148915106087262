/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";
import { types } from "../calendar";

export const schema = {
  name: "calendar",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_CALENDAR_EVENTS.success():
      return { ...state, ...action.response[schema.name] };
    case types.CREATE_DASHBOARD_CALENDAR_EVENT.success():
      return { ...state, ...action.response.calendar };
    default:
      return state;
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
