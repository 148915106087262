import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React from "react";

const Policy = () => {
  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Typography variant={"body2"}>
        By tapping "Log in", you agree to the MonkeyDock
        <Link
          style={{ margin: "0px 4px 4px 4px" }}
          component="button"
          variant="body1"
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Terms of Service
        </Link>
        and acknowledge the MonkeyDock
        <Link
          style={{ margin: "0px 4px 4px 4px" }}
          component="button"
          variant="body1"
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Private Service
        </Link>
      </Typography>
      <Typography variant={"body2"} style={{ marginTop: 5 }}>
        This site is protected by reCAPTCHA and the Google
        <Link
          style={{ margin: "0px 4px 4px 4px" }}
          component="button"
          variant="body1"
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Terms of Service
        </Link>
        and apply
        <Link
          style={{ margin: "0px 4px 4px 4px" }}
          component="button"
          variant="body1"
          onClick={() => {
            console.info("I'm a button.");
          }}
        >
          Private Service
        </Link>
      </Typography>
    </div>
  );
};

export default Policy;
