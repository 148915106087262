import {
  actions as loginActions,
  getEmail,
  getFirstname,
  getIsLogin,
  getLastname,
  getPhone,
  getProfileImg,
  getUsername,
  getCheckUsername,
} from "../redux/modules/authentication";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState } from "react";
import Auth from "../components/widgets/modals/auth";
import { AppBar } from "@material-ui/core";

const useAuth = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => getUsername(state));
  const email = useSelector((state) => getEmail(state));
  const firstname = useSelector((state) => getFirstname(state));
  const lastname = useSelector((state) => getLastname(state));
  const phone = useSelector((state) => getPhone(state));
  const profileImg = useSelector((state) => getProfileImg(state));
  const isLogin = useSelector((state) => getIsLogin(state));

  const loginAuth = bindActionCreators(loginActions, dispatch);
  // const signupAuth = bindActionCreators(loginAuth, dispatch);

  const [openAuth, setOpenAuth] = useState(false);

  return {
    username,
    phone,
    email,
    firstname,
    lastname,
    profileImg,
    isLogin,
    loginAuth,
    openAuth,
    setOpenAuth: (isOpen) => setOpenAuth(isOpen),
  };
};

export default useAuth;
