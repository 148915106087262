/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/
import createReducer from "../../../utils/createReducer";
import { baseUrl } from "../../../utils/url";
export const schema = {
  name: "orders",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = createReducer(schema.name);

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/
export const getSoldOrders = (state) => {
  return state.entities.orders.sold ?? [];
};

export const getPurchasedOrders = (state) => {
  return state.entities.orders.purchased ?? [];
};

export const getOrder = (state, type, id) => {
  return type && id && Object.keys(state.entities.orders).length > 0
    ? state.entities.orders[type].filter((order) => order.id === id)[0]
    : null;
};
