/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import url, { baseUrlImg } from "../../utils/url";
import { FETCH_DATA, POST_DATA } from "../middlewares/api";
import { schema as myProductsSchema } from "./entities/myProducts";
import { schema as productBidsSchema } from "./entities/productBids";
import { schema as myBidsSchema } from "./entities/myBids";
import { schema as paymentSchema } from "./entities/payment";
import { schema as ordersSchema } from "./entities/orders";
import { combineReducers } from "redux";
import { getCreatedAt } from "../../utils/getCreatedAt";
import { actionTypesConstructor, dispatchAction } from "../utils";
import objectToFormData from "../../utils/objectToFormData";

export const types = {
  FETCH_DASHBOARD_MY_PRODUCTS: actionTypesConstructor(
    "DASHBOARD/FETCH_DASHBOARD_MY_PRODUCTS_REQUEST",
    "DASHBOARD/FETCH_DASHBOARD_MY_PRODUCTS_SUCCESS",
    "DASHBOARD/FETCH_DASHBOARD_MY_PRODUCTS_FAILURE"
  ),

  POST_PRODUCT: actionTypesConstructor(
    "DASHBOARD/POST_PRODUCT_REQUEST",
    "DASHBOARD/POST_PRODUCT_SUCCESS",
    "DASHBOARD/POST_PRODUCT_FAILURE"
  ),

  DELETE_PRODUCT: actionTypesConstructor(
    "DASHBOARD/DELETE_PRODUCT_REQUEST",
    "DASHBOARD/DELETE_PRODUCT_SUCCESS",
    "DASHBOARD/DELETE_PRODUCT_FAILURE"
  ),

  POST_PAYMENT_REQUEST: actionTypesConstructor(
    "DASHBOARD/POST_PAYMENT_REQUEST_REQUEST",
    "DASHBOARD/POST_PAYMENT_REQUEST_SUCCESS",
    "DASHBOARD/POST_PAYMENT_REQUEST_FAILURE"
  ),

  FETCH_DASHBOARD_PRODUCT_BIDS: actionTypesConstructor(
    "DASHBOARD/FETCH_DASHBOARD_PRODUCT_BIDS_REQUEST",
    "DASHBOARD/FETCH_DASHBOARD_PRODUCT_BIDS_SUCCESS",
    "DASHBOARD/FETCH_DASHBOARD_PRODUCT_BIDS_FAILURE"
  ),

  FETCH_DASHBOARD_MY_BIDS: actionTypesConstructor(
    "DASHBOARD/FETCH_DASHBOARD_MY_BIDS_REQUEST",
    "DASHBOARD/FETCH_DASHBOARD_MY_BIDS_SUCCESS",
    "DASHBOARD/FETCH_DASHBOARD_MY_BIDS_FAILURE"
  ),

  UPDATE_DASHBOARD_MY_BID: actionTypesConstructor(
    "DASHBOARD/UPDATE_DASHBOARD_MY_BID_REQUEST",
    "DASHBOARD/UPDATE_DASHBOARD_MY_BID_SUCCESS",
    "DASHBOARD/UPDATE_DASHBOARD_MY_BID_FAILURE"
  ),

  DELETE_DASHBOARD_MY_BID: actionTypesConstructor(
    "DASHBOARD/DELETE_DASHBOARD_MY_BID_REQUEST",
    "DASHBOARD/DELETE_DASHBOARD_MY_BID_SUCCESS",
    "DASHBOARD/DELETE_DASHBOARD_MY_BID_FAILURE"
  ),

  FETCH_PAYMENT_SECRET: actionTypesConstructor(
    "DASHBOARD/FETCH_PAYMENT_SECRET_REQUEST",
    "DASHBOARD/FETCH_PAYMENT_SECRET_SUCCESS",
    "DASHBOARD/FETCH_PAYMENT_SECRET_FAILURE"
  ),

  FETCH_PAYMENT_CARDS: actionTypesConstructor(
    "DASHBOARD/FETCH_PAYMENT_CARDS_REQUEST",
    "DASHBOARD/FETCH_PAYMENT_CARDS_SUCCESS",
    "DASHBOARD/FETCH_PAYMENT_CARDS_FAILURE"
  ),
  POST_PAYMENT_CARD: actionTypesConstructor(
    "DASHBOARD/POST_PAYMENT_CARD_REQUEST",
    "DASHBOARD/POST_PAYMENT_CARD_SUCCESS",
    "DASHBOARD/POST_PAYMENT_CARD_FAILURE"
  ),
  FETCH_CHARGE_ENABLED: actionTypesConstructor(
    "DASHBOARD/FETCH_CHARGE_ENABLED_REQUEST",
    "DASHBOARD/FETCH_CHARGE_ENABLED_SUCCESS",
    "DASHBOARD/FETCH_CHARGE_ENABLED_FAILURE"
  ),
  FETCH_STRIPE_DASHBOARD_LINK: actionTypesConstructor(
    "DASHBOARD/FETCH_STRIPE_DASHBOARD_LINK_REQUEST",
    "DASHBOARD/FETCH_STRIPE_DASHBOARD_LINK_SUCCESS",
    "DASHBOARD/FETCH_STRIPE_DASHBOARD_LINK_FAILURE"
  ),
  POST_PAYMENT_PAY: actionTypesConstructor(
    "DASHBOARD/POST_PAYMENT_PAY_REQUEST",
    "DASHBOARD/POST_PAYMENT_PAY_SUCCESS",
    "DASHBOARD/POST_PAYMENT_PAY_FAILURE"
  ),
  FETCH_ORDERS: actionTypesConstructor(
    "DASHBOARD/FETCH_ORDERS_REQUEST",
    "DASHBOARD/FETCH_ORDERS_SUCCESS",
    "DASHBOARD/FETCH_ORDERS_FAILURE"
  ),
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
  dashboard: {
    myProducts: {
      isFetching: false,
      ids: [],
    },
    productBids: {
      isFetching: false,
      ids: [],
    },
    myBids: {
      isFetching: false,
      ids: [],
    },
    payment: {
      paymentSecret: {
        isFetching: false,
      },
      customerCards: {
        isFetching: false,
        isPosting: false,
      },
      chargeEnabled: {
        fetched: false,
        isFetching: false,
        value: true,
      },
      stripeDashboardLink: {
        isFetching: false,
        url: "",
      },
    },
    orders: {
      isFetching: false,
    },
  },
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/
export const actions = {
  loadMyProducts: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getMyProducts();
      if (getState().dashboard.myProducts.ids.length > 0) {
        return null;
      }
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_DASHBOARD_MY_PRODUCTS.all(),
          endpoint,
          myProductsSchema
        )
      );
    };
  },
  deleteMyProduct: (productId) => {
    return async (dispatch, getState) => {
      const endpoint = url.deleteProduct(productId);
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.DELETE_PRODUCT.all(),
          endpoint,
          null,
          productId
        )
      );
    };
  },
  createProduct: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.postProduct();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.POST_PRODUCT.all(),
          endpoint,
          myProductsSchema,
          data
        )
      );
    };
  },
  loadMyProductBids: (productId) => {
    return async (dispatch, getState) => {
      const endpoint = url.getProductBids(productId);
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_DASHBOARD_PRODUCT_BIDS.all(),
          endpoint,
          productBidsSchema
        )
      );
    };
  },
  loadMyBids: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getMyBids();
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_DASHBOARD_MY_BIDS.all(),
          endpoint,
          myBidsSchema
        )
      );
    };
  },
  updateMyBid: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.updateMyBid();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.UPDATE_DASHBOARD_MY_BID.all(),
          endpoint,
          null,
          data
        )
      );
    };
  },
  deleteMyBid: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.deleteMyBid();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.DELETE_DASHBOARD_MY_BID.all(),
          endpoint,
          null,
          data
        )
      );
    };
  },
  sendPaymentRequest: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.sendPaymentRequest(data.productId);
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.POST_PAYMENT_REQUEST.all(),
          endpoint,
          null,
          objectToFormData({ bidIds: JSON.stringify(data.bidIds) })
        )
      );
    };
  },
  loadPaymentSecret: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getPaymentSecret();
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_PAYMENT_SECRET.all(),
          endpoint,
          paymentSchema,
          null
        )
      );
    };
  },
  loadPaymentCards: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getPaymentCards();
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_PAYMENT_CARDS.all(),
          endpoint,
          paymentSchema,
          null
        )
      );
    };
  },
  createPaymentCards: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.createPaymentCard();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.POST_PAYMENT_CARD.all(),
          endpoint,
          paymentSchema,
          data
        )
      );
    };
  },
  loadIsChargeEnabled: () => {
    return async (dispatch, getState) => {
      const endpoint = url.retrieveIsChargeEnabled();
      if (getState().dashboard.payment.chargeEnabled.fetched) return null;
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_CHARGE_ENABLED.all(),
          endpoint,
          paymentSchema,
          null
        )
      );
    };
  },
  loadStripeDashboardLink: () => {
    return async (dispatch, getState) => {
      const endpoint = url.stripeDashboard();
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_STRIPE_DASHBOARD_LINK.all(),
          endpoint,
          paymentSchema,
          null
        )
      );
    };
  },
  makePayment: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.makePayment();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.POST_PAYMENT_PAY.all(),
          endpoint,
          null,
          data
        )
      );
    };
  },
  loadOrders: () => {
    return async (dispatch, getState) => {
      const endpoint = url.getOrders();
      return await dispatch(
        dispatchAction(
          FETCH_DATA,
          types.FETCH_ORDERS.all(),
          endpoint,
          ordersSchema,
          null
        )
      );
    };
  },
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/
const myProducts = (state = initialState.dashboard.myProducts, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_MY_PRODUCTS.request():
      return { ...state, isFetching: true };
    case types.FETCH_DASHBOARD_MY_PRODUCTS.success():
      return { ...state, isFetching: false, ids: action.response.ids };
    case types.POST_PRODUCT.success():
      return { ...state, ids: state.ids.concat(action.response.ids) };
    case types.FETCH_DASHBOARD_MY_PRODUCTS.failure():
      return { ...state, isFetching: false };
    case types.DELETE_PRODUCT.request():
      return { ...state, isFetching: true };
    case types.DELETE_PRODUCT.success():
      return {
        isFetching: false,
        ids: state.ids.filter((id) => id !== action.response.data.productId),
      };
    case types.DELETE_PRODUCT.failure():
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const productBids = (state = initialState.dashboard.productBids, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_PRODUCT_BIDS.request():
      return { ...state, isFetching: true };
    case types.FETCH_DASHBOARD_PRODUCT_BIDS.success():
      return { ...state, isFetching: false, ids: action.response.ids };
    case types.FETCH_DASHBOARD_PRODUCT_BIDS.failure():
      return { ...state, isFetching: false };
    default:
      return state;
  }
};

const myBids = (state = initialState.dashboard.myBids, action) => {
  switch (action.type) {
    case types.FETCH_DASHBOARD_MY_BIDS.request():
      return { ...state, isFetching: true };
    case types.FETCH_DASHBOARD_MY_BIDS.success():
      return { ...state, isFetching: false, ids: action.response.ids };
    case types.DELETE_DASHBOARD_MY_BID.success():
      return {
        ...state,
        isFetching: false,
        ids: state.ids.filter((id) => id !== action.response.data.bidId),
      };
    case types.FETCH_DASHBOARD_MY_BIDS.failure():
    default:
      return state;
  }
};

const payment = (state = initialState.dashboard.payment, action) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_SECRET.request():
      return { ...state, paymentSecret: { isFetching: true } };
    case types.FETCH_PAYMENT_SECRET.success():
      return { ...state, paymentSecret: { isFetching: false } };
    case types.FETCH_PAYMENT_SECRET.failure():
      return { ...state, paymentSecret: { isFetching: false } };
    case types.POST_PAYMENT_CARD.request():
      return {
        ...state,
        customerCards: (pre) => ({ ...pre, isPosting: true }),
      };
    case types.POST_PAYMENT_CARD.success():
      return {
        ...state,
        customerCards: (pre) => ({ ...pre, isPosting: false }),
      };
    case types.POST_PAYMENT_CARD.failure():
      return {
        ...state,
        customerCards: (pre) => ({ ...pre, isPosting: false }),
      };
    case types.FETCH_CHARGE_ENABLED.request():
      return {
        ...state,
        chargeEnabled: { isFetching: false, fetched: false, value: true },
      };
    case types.FETCH_CHARGE_ENABLED.success():
      return {
        ...state,
        chargeEnabled: {
          isFetching: false,
          fetched: true,
          value: action.response.payment.chargesEnabled,
        },
      };
    case types.FETCH_CHARGE_ENABLED.failure():
      return {
        ...state,
        chargeEnabled: { isFetching: false, value: true, fetched: false },
      };
    case types.FETCH_STRIPE_DASHBOARD_LINK.request():
      return {
        ...state,
        stripeDashboardLink: { ...state, isFetching: true },
      };
    case types.FETCH_STRIPE_DASHBOARD_LINK.success():
      return {
        ...state,
        stripeDashboardLink: {
          isFetching: false,
          url: action.response.payment.url,
        },
      };
    case types.FETCH_STRIPE_DASHBOARD_LINK.failure():
      return {
        ...state,
        stripeDashboardLink: { isFetching: false, url: "" },
      };
    default:
      return state;
  }
};

const orders = (state = initialState.dashboard.orders, action) => {
  switch (action.type) {
    case types.FETCH_ORDERS.request():
      return { ...state, isFetching: true };
    case types.FETCH_ORDERS.success():
      return { ...state, isFetching: false };
    case types.FETCH_ORDERS.failure():
      return { ...state, isFetching: true };
    default:
      return state;
  }
};

const reducer = combineReducers({
  myProducts,
  productBids,
  myBids,
  payment,
  orders,
});
export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														   *
 * *********************************************************************************************************************/
export const getIsFetchingMyProduct = (state) => {
  return state.dashboard.myProducts.isFetching;
};

export const getMyProducts = (state) => {
  return state.dashboard.myProducts.ids.map((product) => {
    return state.entities.myProducts[product];
  });
};

export const getIsFetchingMyProductBids = (state) => {
  return state.dashboard.productBids.isFetching;
};

export const getProductBids = (state) => {
  return (
    state.dashboard.productBids.ids &&
    state.dashboard.productBids.ids.map((bid) => {
      const bidInfo = state.entities.productBids[bid];
      return {
        ...bidInfo,
        buyerInfo: {
          ...bidInfo.buyerInfo,
          profileImg: baseUrlImg() + bidInfo.buyerInfo.profileImage,
        },
        createdAt: getCreatedAt(bidInfo.createdAtUtc),
      };
    })
  );
};

export const isFetchingMyBids = (state) => {
  return state.dashboard.myBids.isFetching;
};

export const getMyBidsOnConfirming = (state) => {
  return state.dashboard.myBids.ids.length > 0
    ? state.dashboard.myBids.ids
        .map(
          (myBid) =>
            state.entities.myBids[myBid].isConfirmedBySeller &&
            !state.entities.myBids[myBid].isConfirmedByBuyer && {
              ...state.entities.myBids[myBid],
              productImage:
                baseUrlImg() + state.entities.myBids[myBid].productImage,
            }
        )
        .filter((each) => each)
    : [];
};

export const getMyBidsOnWaiting = (state) => {
  return state.dashboard.myBids.ids.length > 0
    ? state.dashboard.myBids.ids
        .map(
          (myBid) =>
            !state.entities.myBids[myBid].isConfirmedBySeller &&
            !state.entities.myBids[myBid].isConfirmedByBuyer && {
              ...state.entities.myBids[myBid],
              createdAt: getCreatedAt(state.entities.myBids[myBid].createAt),
              productImage:
                baseUrlImg() + state.entities.myBids[myBid].productImage,
            }
        )
        .filter((each) => each)
    : [];
};

export const getChargeEnabled = (state) => {
  return state.dashboard.payment.chargeEnabled.value;
};

export const getStripeDashboardLink = (state) => {
  return state.dashboard.payment.stripeDashboardLink.url;
};
