/***********************************************************************************************************************
 * 													SCHEMA 														   *
 * *********************************************************************************************************************/

import { types } from "../dashboard";

export const schema = {
  name: "payment",
  id: "id",
};

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const reducer = (state = {}, action) => {
  switch (action.type) {
    case types.FETCH_PAYMENT_SECRET.success():
      return { ...state, ...action.response[schema.name] };
    case types.FETCH_PAYMENT_CARDS.success():
      return { ...state, cards: action.response[schema.name].cards.data };
    default:
      return {};
  }
};

export default reducer;

/***********************************************************************************************************************
 * 													SELECT  														   *
 * *********************************************************************************************************************/

export const retrieveCards = (state) => {
  return state.entities.payment.cards ? state.entities.payment.cards : [];
};

export const retrievePaymentSecret = (state) => {
  return state.entities.payment.clientSecret
    ? state.entities.payment.clientSecret
    : "";
};
