import { combineReducers } from "redux";
import homeProducts from "./homeProducts";
import exploreProducts from "./exploreProducts";
import productDetail from "./productDetail";
import categories from "./categories";
import myProducts from "./myProducts";
import productBids from "./productBids";
import auth from "./auth";
import productConditions from "./productConditions";
import myBids from "./myBids";
import payment from "./payment";
import orders from "./orders";
import parcels from "./parcels";
import innerContent from "./innerContent";
import calendar from "./calendar";

const rootReducer = combineReducers({
  homeProducts,
  exploreProducts,
  productDetail,
  categories,
  parcels,
  myProducts,
  productBids,
  productConditions,
  auth,
  myBids,
  payment,
  orders,
  innerContent,
  calendar,
});

export default rootReducer;
