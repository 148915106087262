/***********************************************************************************************************************
 * 													CONSTANTS 														   *
 * *********************************************************************************************************************/
import { combineReducers } from "redux";
import url, { baseUrlImg } from "../../utils/url";
import { FETCH_DATA, POST_DATA } from "../middlewares/api";
import { schema } from "./entities/auth";
import { dispatch } from "react-hot-toast";
import { JWT_EXPIRES_IN, JWT_SECRET, sign, decode } from "../../utils/jwt";
import { actionTypesConstructor, dispatchAction } from "../utils";

export const types = {
  CONFIGURE_AUTH: "AUTH/CONFIGURE_AUTH",
  LOGIN_REQUEST: "AUTH/LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE: "AUTH/LOGIN_FAILURE",

  SIGNUP_REQUEST: "AUTH/SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "AUTH/SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "AUTH/SIGNUP_FAILURE",

  CHECK_USERNAME_REQUEST: "AUTH/CHECK_USERNAME_REQUEST",
  CHECK_USERNAME_SUCCESS: "AUTH/CHECK_USERNAME_SUCCESS",
  CHECK_USERNAME_FAILURE: "AUTH/CHECK_USERNAME_FAILURE",

  REQUEST_PHONE_CODE: actionTypesConstructor(
    "AUTH/REQUEST_PHONE_CODE_REQUEST",
    "AUTH/REQUEST_PHONE_CODE_SUCCESS",
    "AUTH/REQUEST_PHONE_CODE_FAILURE"
  ),

  LOGOUT: "AUTH/LOGOUT",
};

/***********************************************************************************************************************
 * 													STATE   														   *
 * *********************************************************************************************************************/
const initialState = {
  ids: [],
  isFetching: false,
  isCheckingUsername: false,
  active: false, // login flag
  phone: null,
};

/***********************************************************************************************************************
 * 													ACTIONS 														   *
 * *********************************************************************************************************************/

export const actions = {
  configureAuth: () => {
    return async (dispatch, getState) => {
      if (localStorage.getItem("md.client")) {
        const payload = decode(localStorage.getItem("md.client"));
        getState().entities.auth[payload.userId] = payload;
        return await dispatch(configureAuth());
      }
      return null;
    };
  },
  login: (email, password) => {
    return async (dispatch, getState) => {
      const endpoint = url.signIn();
      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);
      return await dispatch(loginRequest(endpoint, formdata));
    };
  },
  signup: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.signUp();
      return await dispatch(signUpRequest(endpoint, data));
    };
  },
  logout: () => {
    localStorage.removeItem("md.client");
    return async (dispatch, getState) => {
      await dispatch({ type: types.LOGOUT });
      return null;
    };
  },
  checkUsername: (username) => {
    return async (dispatch, getState) => {
      const endpoint = url.getCheckUsername(username);
      return await dispatch(checkUsernameRequest(endpoint));
    };
  },
  requestPhoneCode: (data) => {
    return async (dispatch, getState) => {
      const endpoint = url.phoneVerification();
      return await dispatch(
        dispatchAction(
          POST_DATA,
          types.REQUEST_PHONE_CODE.all(),
          endpoint,
          null,
          data
        )
      );
    };
  },
};

const loginRequest = (endpoint, data) => ({
  [POST_DATA]: {
    endpoint,
    data,
    schema,
    types: [types.LOGIN_REQUEST, types.LOGIN_SUCCESS, types.LOGIN_FAILURE],
  },
});

const signUpRequest = (endpoint, data) => ({
  [POST_DATA]: {
    endpoint,
    data,
    schema,
    types: [types.SIGNUP_REQUEST, types.SIGNUP_SUCCESS, types.SIGNUP_FAILURE],
  },
});

const configureAuth = () => ({
  type: types.CONFIGURE_AUTH,
});

const checkUsernameRequest = (endpoint) => ({
  [FETCH_DATA]: {
    endpoint,
    schema,
    types: [
      types.CHECK_USERNAME_REQUEST,
      types.CHECK_USERNAME_SUCCESS,
      types.CHECK_USERNAME_FAILURE,
    ],
  },
});

/***********************************************************************************************************************
 * 													REDUCERS 														   *
 * *********************************************************************************************************************/

const client = (state = initialState, action) => {
  switch (action.type) {
    case types.CONFIGURE_AUTH:
      return {
        ...state,
        active: true,
        ids: [decode(localStorage.getItem("md.client")).userId],
      };
    case types.LOGIN_REQUEST:
      return { ...state, isFetching: true };
    case types.SIGNUP_REQUEST:
      return { ...state, isFetching: true };
    case types.LOGIN_SUCCESS:
      const accessToken = sign(
        action.response.auth[action.response.ids[0]],
        JWT_SECRET,
        { expiresIn: JWT_EXPIRES_IN }
      );
      localStorage.setItem("md.client", accessToken);
      return {
        ...state,
        isFetching: false,
        active: true,
        ids: state.ids.concat(action.response.ids),
      };
    case types.SIGNUP_SUCCESS:
      localStorage.setItem(
        "md.client",
        sign(action.response.auth[action.response.ids[0]], JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        })
      );
      return {
        ...state,
        isFetching: false,
        active: true,
        ids: state.ids.concat(action.response.ids),
      };
    case types.LOGIN_FAILURE:
      return { ...state, isFetching: false };
    case types.SIGNUP_FAILURE:
      return { ...state, isFetching: false };
    case types.CHECK_USERNAME_REQUEST:
      return { ...state, isCheckingUsername: true };
    case types.CHECK_USERNAME_SUCCESS:
      return { ...state, isCheckingUsername: false };
    case types.CHECK_USERNAME_FAILURE:
      return { ...state, isCheckingUsername: false };
    case types.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
};

const reducer = combineReducers({ client });

export default reducer;

/***********************************************************************************************************************
 * 													SELECTORS 														   *
 * *********************************************************************************************************************/
export const getUserId = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    state.entities.auth[state.authentication.client.ids[0]].userId
  );
};

export const getUsername = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    state.entities.auth[state.authentication.client.ids[0]].username
  );
};

export const getEmail = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    state.entities.auth[state.authentication.client.ids[0]].email
  );
};

export const getFirstname = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    state.entities.auth[state.authentication.client.ids[0]].firstName
  );
};

export const getLastname = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    state.entities.auth[state.authentication.client.ids[0]].lastName
  );
};

export const getPhone = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    state.entities.auth[state.authentication.client.ids[0]].phone
  );
};

export const getProfileImg = (state) => {
  return (
    state.authentication.client.ids.length > 0 &&
    baseUrlImg() +
      state.entities.auth[state.authentication.client.ids[0]].profileImage
  );
};

export const getIsLogin = (state) => {
  return state.authentication.client.active;
};
