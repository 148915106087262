import {
  actions as categoryActions,
  getCategories,
  getTags,
  getCategory,
} from "../redux/modules/categories";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useEffect, useState } from "react";
import useMounted from "./useMounted";

const useFetchCategories = (categoryId = null) => {
  const dispatch = useDispatch();
  const categoriesDispatcher = bindActionCreators(categoryActions, dispatch);
  const mounted = useMounted();
  const categoriesGetter = useSelector((state) => getCategories(state));
  const tagsGetter = useSelector((state) => getTags(state, categoryId));
  const categoryGetter = useSelector((state) => getCategory(state, categoryId));

  const fetchCategories = async () => {
    const res = await categoriesDispatcher.loadCategories();
    console.log(res);
  };

  useEffect(() => {
    if (mounted.current) {
      fetchCategories();
    }
  }, [mounted]);

  return { categoriesGetter, tagsGetter, categoryGetter };
};

export default useFetchCategories;
