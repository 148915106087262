import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  lighten,
  Link,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import MenuIcon from "../icons/Menu";

import SearchIcon from "../icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Auth from "./widgets/modals/auth";
import User from "../icons/User";
import ClientPopover from "./widgets/modals/auth/ClientPopover";
import useAuth from "../hooks/useAuth";
import useFetchCategories from "../hooks/useFetchCategories";
import Skeleton from "@material-ui/core/Skeleton";
import { useNavigate } from "react-router";
import MetaMaskConnect from "../icons/MetamaskConnect";
import useWalletConfigurator from "../hooks/aboutWallet/useWalletConfigurator";
import useWallet from "../hooks/aboutWallet/useWallet";
import numeral from "numeral";

const MainNavbar = (props) => {
  const [openAuth, setOpenAuth] = useState(false);
  const { isLogin, loginAuth } = useAuth();
  const { categoriesGetter } = useFetchCategories();
  const navigator = useNavigate();
  const theme = useTheme();
  const { onSidebarMobileOpen } = props;

  useWalletConfigurator();
  const {
    walletAccountGetter,
    isMetaMaskInstalledGetter,
    mdcBalanceGetter,
    ethBalanceGetter,
    walletConnector,
    mdcBalancePeeker,
    ethBalancePeeker,
  } = useWallet();

  useEffect(() => {
    loginAuth.configureAuth();
  }, []);

  // get MD Balance
  useEffect(() => {
    if (walletAccountGetter) {
      mdcBalancePeeker(walletAccountGetter);
      ethBalancePeeker(walletAccountGetter);
    }
  }, [walletAccountGetter]);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        color: "text.secondary",
      }}
    >
      <Toolbar sx={{ minHeight: 64, mt: 1 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Link
          component={RouterLink}
          to="/home"
          underline="none"
          variant="h5"
          color={"primary"}
          sx={{
            display: {
              sx: "none",
            },
          }}
        >
          MonkeyDock
        </Link>

        <Box
          sx={{
            mx: 1.5,
            maxWidth: "100%",
            width: 500,
            display: {
              md: "flex",
              xs: "none",
            },
          }}
        >
          <TextField
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.target.value &&
                  navigator(`/explore?name=${e.target.value}`, {
                    state: { name: e.target.value },
                  });
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            placeholder="Search products"
            variant="outlined"
          />
        </Box>
        {/*<Link*/}
        {/*  color="primary"*/}
        {/*  component={RouterLink}*/}
        {/*  to="/docs"*/}
        {/*  underline="none"*/}
        {/*  variant="h6"*/}
        {/*>*/}
        {/*  <LocationOnIcon />*/}
        {/*  San Jose: 30 miles + Shipping*/}
        {/*</Link>*/}
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "background.default",
            borderRadius: 1,
            display: { xs: "none", md: "flex" },
            overflow: "hidden",
            px: 0.5,
            py: 0.5,
          }}
        >
          {!isMetaMaskInstalledGetter && !walletAccountGetter && (
            <Typography
              variant="body2"
              sx={{ display: { xs: "none", md: "inline" } }}
            >
              <Link
                href={
                  "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
                }
                target="_blank"
              >
                Install MateMask
              </Link>
            </Typography>
          )}
          {isMetaMaskInstalledGetter && walletAccountGetter ? (
            <Box sx={{ display: { xs: "none", lg: "flex" } }}>
              <Typography variant="body2">ETH: </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {ethBalanceGetter
                  ? numeral(ethBalanceGetter).format(`0,0.00`)
                  : "0.00"}
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                MDC:
              </Typography>
              <Typography variant="body2" sx={{ marginLeft: 1 }}>
                {mdcBalanceGetter
                  ? numeral(mdcBalanceGetter).format(`0,0.00`)
                  : "0.00"}
              </Typography>
            </Box>
          ) : isMetaMaskInstalledGetter ? (
            <>
              <Button fullWidth onClick={walletConnector}>
                <Typography variant={"body2"}>Connect to wallet</Typography>{" "}
              </Button>
            </>
          ) : (
            ""
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: theme.palette.background.paper,
              px: 1,
              ml: { xs: 0, lg: 1 },

              borderRadius: 1,
              py: 0.5,
            }}
          >
            {isMetaMaskInstalledGetter && walletAccountGetter && (
              <Typography variant="body2">
                {`${walletAccountGetter.slice(
                  0,
                  6
                )}...${walletAccountGetter.slice(-4)}`}
              </Typography>
            )}

            <img
              src={MetaMaskConnect}
              alt={"MetaMaskConnect"}
              style={{
                marginLeft: 5,
                cursor: "pointer",
                height: 25,
                width: 25,
              }}
            />
          </Box>
        </Box>

        <Divider
          orientation="vertical"
          sx={{
            height: 32,
            mx: 2,
            display: {
              display: "none",
              md: "flex",
            },
          }}
        />
        <Box
          sx={{
            alignItems: "center",
            display: {
              display: "flex",
            },
          }}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/browse"
            underline="none"
            variant="h6"
            sx={{
              display: {
                display: "none",
                md: "flex",
              },
            }}
          >
            About
          </Link>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2,
              display: {
                display: "none",
                md: "flex",
              },
            }}
          />
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/docs"
            underline="none"
            variant="h6"
            sx={{
              display: {
                display: "none",
                md: "flex",
              },
            }}
          >
            Help
          </Link>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2,
              display: {
                display: "none",
                md: "flex",
              },
            }}
          />
          {isLogin ? (
            <ClientPopover />
          ) : (
            <Button
              size="small"
              color="textSecondary"
              variant="h6"
              onClick={() => {
                setOpenAuth(true);
              }}
            >
              Log in
            </Button>
          )}
        </Box>
      </Toolbar>

      <Auth openAuth={openAuth} setOpenAuth={(isOpen) => setOpenAuth(isOpen)} />

      <Box
        sx={{
          mx: 1.5,
          maxWidth: "100%",
          minWidth: "90%",
          marginBottom: 3,
          display: {
            md: "none",
            sx: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <TextField
          fullWidth
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.target.value &&
                navigator(`/explore?name=${e.target.value}`, {
                  state: { name: e.target.value },
                });
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
          placeholder="Search products"
          variant="outlined"
        />
      </Box>

      {/*category options*/}
      {categoriesGetter.length > 0 && (
        <Toolbar sx={{ display: { xs: "none", md: "flex" } }}>
          <Box>
            {categoriesGetter.map((category) => (
              <Link
                component={RouterLink}
                to={`/explore?category=${category.id}`}
                state={{ category: category.id, categoryName: category.name }}
                underline="none"
                variant="subtitle2"
                color={"textPrimary"}
                sx={{
                  mr: 2,
                }}
              >
                {category.name}
              </Link>
            ))}
          </Box>
        </Toolbar>
      )}

      {!onSidebarMobileOpen && categoriesGetter.length <= 0 && (
        <Skeleton
          animation="wave"
          height={55}
          style={{ margin: "0 20px 0 20px" }}
        />
      )}
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
