import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
} from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import MenuIcon from "../../icons/Menu";
import AccountPopover from "./AccountPopover";
import ContactsPopover from "./ContactsPopover";
import ContentSearch from "./ContentSearch";
import LanguagePopover from "./LanguagePopover";
import Logo from "../Logo";
import NotificationsPopover from "./NotificationsPopover";
import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import ClientPopover from "../widgets/modals/auth/ClientPopover";
import useRetrieveIsChargeEnabled from "../../hooks/aboutPayment/useRetrieveIsChargeEnabled";
import useTheme from "@material-ui/core/styles/useTheme";

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { isLogin, loginAuth } = useAuth();
  const theme = useTheme();

  useEffect(() => {
    loginAuth.configureAuth();
  }, []);

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Link
          component={RouterLink}
          to="/home"
          underline="none"
          variant="h5"
          color={theme.palette.primary.main}
          sx={{
            display: {
              sx: "none",
            },
          }}
        >
          MonkeyDock
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/*<LanguagePopover />*/}
        {/*<Box sx={{ ml: 1 }}>*/}
        {/*  <ContentSearch />*/}
        {/*</Box>*/}
        {/*<Box sx={{ ml: 1 }}>*/}
        {/*  <ContactsPopover />*/}
        {/*</Box>*/}
        {/*<Box sx={{ ml: 1 }}>*/}
        {/*  <NotificationsPopover />*/}
        {/*</Box>*/}
        <Box sx={{ ml: 2 }}>
          {/*<AccountPopover />*/}
          <ClientPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
