import { useDispatch } from "../../store";
import { bindActionCreators } from "redux";
import {
  actions as dashboardAction,
  getChargeEnabled,
  getStripeDashboardLink,
  types,
} from "../../redux/modules/dashboard";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import useMounted from "../useMounted";
import { useSelector } from "react-redux";

const useRetrieveIsChargeEnabled = () => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const RetrieveIsChargeEnabledDispatcher = bindActionCreators(
    dashboardAction,
    dispatch
  );

  const retrieveIsChargeEnabled = async () => {
    const res = await RetrieveIsChargeEnabledDispatcher.loadIsChargeEnabled();
    console.log(res);
    if (res?.type === types.FETCH_CHARGE_ENABLED.failure()) {
      toast.error(res.message);
    }
  };

  const retrieveStripeDashboardLink = async () => {
    const res =
      await RetrieveIsChargeEnabledDispatcher.loadStripeDashboardLink();
    console.log(res);
    if (res?.type === types.FETCH_STRIPE_DASHBOARD_LINK.failure()) {
      toast.error(res.message);
    }
  };

  useEffect(() => {
    if (mounted.current) {
      retrieveIsChargeEnabled();
      retrieveStripeDashboardLink();
    }
  }, [mounted]);

  return {
    isChargeEnabled: useSelector((state) => getChargeEnabled(state)),
    stripeDashboardLink: useSelector((state) => getStripeDashboardLink(state)),
  };
};

export default useRetrieveIsChargeEnabled;
